import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '',data: { revalidate: 30 }, loadChildren: () => import('./public-offering/public-offering.module').then(m => m.PublicOfferingModule) },
  { path: 'halka-arz',data: { revalidate: 30 },loadChildren: () => import('./public-offering/public-offering.module').then(m => m.PublicOfferingModule) },
  { path: 'ipo-calendar',data: { revalidate: 30 }, loadChildren: () => import('./ipo-calendar/ipo-calendar.module').then(m => m.IpoCalendarModule) },
  { path: 'company-news',data: { revalidate: 30 }, loadChildren: () => import('./company-news/company-news.module').then(m => m.CompanyNewsModule) },
  { path: 'crypto',data: { revalidate: 30 }, loadChildren: () => import('./coins/coins.module').then(m => m.CoinsModule) },
  { path: 'analysis',data: { revalidate: 30 }, loadChildren: () => import('./analysis/analysis.module').then(m => m.AnalysisModule) },
  { path: 'info',data: { revalidate: 30 }, loadChildren: () => import('./info/info.module').then(m => m.InfoModule) },
  { path: 'dividend',data: { revalidate: 30 }, loadChildren: () => import('./dividend/dividend.module').then(m => m.DividendModule) },
  { path: 'archive',data: { revalidate: 30 }, loadChildren: () => import('./archive/archive.module').then(m => m.ArchiveModule) },
  { path: 'terms',data: { revalidate: 30 }, loadChildren: () => import('./terms/terms.module').then(m => m.TermsModule) },
  { path: 'privacy',data: { revalidate: 30 }, loadChildren: () => import('./privacy/privacy.module').then(m => m.PrivacyModule) },
  { path: 'legacy',data: { revalidate: 30 }, loadChildren: () => import('./legacy/legacy.module').then(m => m.LegacyModule) },
  { path: 'calculate',data: { revalidate: 30 }, loadChildren: () => import('./calculate/calculate.module').then(m => m.CalculateModule) },
  { path: 'contacts', loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule) },
  { path: '404', loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule) },
  { path: 'search/:search', loadChildren: () => import('./search/search.module').then(m => m.SearchModule) },  
  { path: 'stock-recommendation',data: { revalidate: 30 },  loadChildren: () => import('./stock-recommendation/stock-recommendation.module').then(m => m.StockRecommendationModule) },  
  { path: 'capital-increase',data: { revalidate: 30 },  loadChildren: () => import('./capital-increase/capital-increase.module').then(m => m.CapitalIncreaseModule) },
  { path: '**', pathMatch: 'full', loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutes { }
