export class UserWebPushInfo {
  id: string;
  endpoint: string;
  publicKey: string;
  auth: string;
  permission:Boolean;
  browser:string;
  deviceType:string;
  os:string;
  userAgent:string;
  constructor(
      ) { }
}
