import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { toFormat } from 'src/app/resource';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JsonldService {
  private jsonSnippet: HTMLScriptElement;
  private graphObjects: any[] = [];
  index:number;
  
  
  constructor(@Inject(DOCUMENT) private doc) {
    this.createJsonLd(); 
  }

  private createFirstJsonSnippet(): HTMLScriptElement {
    var script = this.doc.createElement('script');
    script.setAttribute('type', 'application/ld+json');
    script.setAttribute('class', 'jsonsnippet');
    this.doc.head.appendChild(script);
    return script;
  }

  createJsonLd() {
    this.jsonSnippet = this.createFirstJsonSnippet();
  }

  removeJsonSnippet(){
    var elements=this.doc.getElementsByClassName('jsonsnippet');
    while(elements.length > 0 && elements[0].parentNode!=null){
      elements[0]?.parentNode?.removeChild(elements[0]);
  }
  //element.parentNode.removeChild(element);
  }
  
  setOrganization() {
    const schema = {
     "@type": "Organization",
     url: toFormat(environment.domain, environment.defaultRegion, environment.defaultLanguage, ''),
     logo: environment.logoUrl,
     name: environment.name,
     "sameAs": [
      environment.domain,
      environment.facebookUrl,
      environment.twitterUrl,
      environment.instagramUrl,
      environment.youtubeUrl
    ],
    "address": {
      "@type": "PostalAddress",
      addressLocality: "Istanbul",
      "addressRegion": "TR",
      "postalCode": "34758",
      "streetAddress": "Küçükbakkalköy, Selvili Sok. No:4/48, 34854 Ataşehir/İstanbul, Türkiye"
    },
     "contactPoint": {
       "@type": "ContactPoint",
       telephone: environment.phone,
       contactType: "technical support",
       areaServed: environment.defaultLanguage,
       availableLanguage: environment.defaultLanguage
       },
       "email": "info@mansel.com.tr",  
   }
   this.updateJsonSnippet(schema);
 }

  setArticle(title,dateCreated,subtitle){
    this.updateJsonSnippet({
      '@type': 'Article',
      headline: title,
      image: [environment.logoUrl],
      datePublished: dateCreated,
      author: [{
        '@type': 'Person',
        name: environment.name,
        url: toFormat(environment.domain, environment.defaultRegion, environment.defaultLanguage, '')
      }],
      description:subtitle
    });
  }

  setBreadCrumbListItem(domain,lang,title,linkSuffix){
    this.updateJsonSnippet({
      '@type': 'BreadcrumbList',
      '@id':linkSuffix+"/#breadcrumbList",
      itemListElement: [
        {'@type':'ListItem',
        position: 1,
        item:{
          '@type':'WebPage',
          '@id':domain+"/"+lang+"/",
          url:domain+"/"+lang+"/",
          name:title} 
        },
        {'@type':'ListItem',
        position: 2,
        item:{
          '@type':'WebPage',
          '@id':domain+"/"+lang+linkSuffix,
          url:domain+"/"+lang+linkSuffix,
          name:title} 
        },
        {'@type':'ListItem',
        position: 3,
        item:{
          '@type':'WebPage',
          '@id':domain+"/"+lang+linkSuffix,
          url:domain+"/"+lang+linkSuffix,
          name:title} 
        }
      ]
    });
  }
  
  setArchiveType(url,title,description,domain){
    this.updateJsonSnippet({
      "@type": ["ArchiveComponent"],
      "url": url,
      "name": title,
      "description": description,
      "holdingArchive": {
        "@type": "ArchiveOrganization",
        "name": "Halkaarz.info",
        "url": domain
      }
    })
  }

  setTableType(title){
    this.updateJsonSnippet({
      '@type': 'Table',
      about: title+' Tablosu',
    })
  }

  setWebSiteType(domain,lang){
    this.updateJsonSnippet({
      "@type": "WebSite",
      "url": environment.domain,
      "potentialAction": [
        {
          "@type": "SearchAction",
          "target": domain+'/'+lang+"/search/{queryKeyword}",
          "query-input": "required name=queryKeyword"
        }
      ]
    });
  }
  
  setNewsArticleType(pageTitle,object:any,domain,lang){
    this.updateJsonSnippet({
      "@type": "NewsArticle",
      "image": {
        "@type": "ImageObject",
        url: "newsimageurl lazım buraya",
        height: "360",
        width: "640"
      },
      headline:pageTitle,
      alternativeHeadline:object.title,
      articleBody:object.info,
      description:object.subTitle,
      "keywords": [
        "halka",
        "arz",
        "nedir",
        "amaci",
        "nelerdir",
        "hisse",
        "alinir"
      ],
      datePublished:object.createDate,
      inLanguage: object.language,
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": domain+lang+object.url,
      },
      "articleSection": "Ekonomi",
      "typicalAgeRange": "7-",
      "genre": "news",
      "isFamilyFriendly": "True",
      publishingPrinciples: domain+lang+"privacy",
      "publisher": {
        "@type": "NewsMediaOrganization",
        "name": "Mansel",
        "logo": {
          "@type": "ImageObject",
          "url": environment.logoUrl
        }
      }

    })
  }
  
  private updateJsonSnippet(schema: any) {
    this.createJsonLd();
    const graph = { '@context': 'https://schema.org', '@graph': [schema] };
    this.jsonSnippet.textContent = JSON.stringify(graph);
  }
  
  
  // adding defaultUrl and siteUrl and refactoring service 
  get defaultUrl(): string {
    return toFormat(environment.domain, environment.defaultRegion, environment.defaultLanguage, '');
  }
  get siteUrl(): string {
    return toFormat(environment.domain, environment.defaultRegion, environment.defaultLanguage, '');
  }
}
