<div id="wrapper" class="wrapper clearfix">
  <section id="tabs" class="project-tab tab-edit">
    <div class="container pairblank">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-8 mb-30-xs mb-30-sm">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="container pairblank">
                <div class="row mb-3" style="padding: 15px;">
                  <form [formGroup]="proportionalDistributionForm" (ngSubmit)="onSubmit()" novalidate>
                    <h1><a [routerLink]="['/calculate/oransal-dagitim-hesaplama']" title="Oransal Dağıtım Hesaplama">Oransal Dağıtım Hesaplama</a></h1>
                    <p>Halka Arz Büyüklüğü:</p>
                    <input type="number" formControlName="ipoSize" class="form-control mt-3 mb-3"
                           placeholder="Halka Arz Büyüklüğünü Giriniz" aria-describedby="basic-addon2">
                    <p>Talep Miktarı:</p>
                    <input type="number" formControlName="demandQuantity" class="form-control mt-3 mb-3"
                           placeholder="Talep Miktarı Giriniz" aria-d  escribedby="basic-addon2">
                    <p>Alınacak Lot Sayısı:</p>
                    <input type="number" formControlName="numberOfLotsToBought" class="form-control mt-3 mb-3"
                           placeholder="Alınacak Lot Sayısı Giriniz" aria-describedby="basic-addon2">
                    <label class='mt-3 mb-3' *ngIf="result" style="display:block">Sonuç: %{{result}}</label>
                    <button class="btn btn-primary mt-3 mb-3" i18n="@@buttonCalculator">Hesapla</button>
                  </form>
                  <div class="widget market-pricing market-list card mb-3" style="position: relative; padding:10px;">
                    <div class="row">
                      <div class="col-4" style="width:auto;height:auto;">
                        <picture>
                          <source media="(max-width: 90px)" srcset="oransal-dagitim.webp" />
                          <source media="(min-width: 90px)" srcset="oransal-dagitim.webp" />
                          <img loading="lazy" width="90px" height="60px" src="oransal-dagitim.webp" alt="Oransal Dağıtım"
                               title="Oransal Dağıtım">
                        </picture>
                      </div>
                      <div class="col-8">
                        <h3 class="h5"><strong>Oransal Dağıtım Nedir?</strong></h3>Bir yatırım amacı olarak öne çıkan oransal dağıtım, şirketler üzerinden gerçekleştirilen bir işlemdir.
                      </div>
                      <div class="w-100"></div>
                      <br>
                      <p><strong>Oransal Dağıtım,</strong> borsada yeni halka arz edilecek şirketler için farklı talep toplama yöntemleri vardır. Oransal dağıtım yöntemi bunlardan biridir. Halka arzın açık olduğu süre boyunca yatırımcılardan taban fiyatın üzerinde veya ona eşit çeşitli fiyatlardan tekliflerin toplandığı bir mekanizmadır. Oransal dağıtım ile halka arz olan bir şirket için, katılımcıların halka arza dahil ettikleri paranın büyüklüğünün önemi vardır. Eşit dağıtım için ise katılımcı sayısının önemi vardır.</p>
                      <p>Örneğin; X şirketi oransal olarak tanesini 20 TL’den dağıtacağını açıkladığı 10.000.000 lot pay senedi (hisse senedini) için talep toplasın. 3 günün sonunda şirketin normalde 200.000.000 TL toplamayı düşündüğü halka arz için insanlar toplamda 10 milyar TL’lik talepte bulunmuş olsun. Kimisi 10.000 TL’lik hisse istemiş olsun kimisi 50.000 TL kimisi de 500 TL diyelim.</p>
                      <p>10.000.000.000 TL / 200.000.000 = 50 kat demektir. Yani şirketin halka arzına 50 kat talep gelmiştir. Şirket oransal olarak şöyle bir hesap yapar. 100 ü 50 ye böler. Yüzde 2 gibi bir oran bulur. Yani her talep edene istediği maksimum hisse miktarının yüzde 2’si kadar hisse verir.</p>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4">
          <app-draft-calculate></app-draft-calculate>
          <app-draft-dividend [toYear]="toYear"></app-draft-dividend>
          <app-summary-info></app-summary-info>
          <app-summary-analysis [imageStatus]="true" [count]="5"></app-summary-analysis>
        </div>
      </div>
    </div>
  </section>
  <div id="back-to-top" class="backtop">
    <fa-icon [icon]="faArrUp"></fa-icon>
  </div>
