import { ActiveToast, ToastrService } from "ngx-toastr";
import { ResponseMessageType } from "../enums/response-message-type.enum";
import { ResponseMessage } from "../models/response-message";

export class MessageToastrShow {
    public  static show(toastr: ToastrService,responseMessage:ResponseMessage): ActiveToast<any> {
        switch (responseMessage?.type) {
            case ResponseMessageType.SHOW:
                return toastr.show(responseMessage.message);
            case ResponseMessageType.INFO:
                return  toastr.info(responseMessage.message);
            case ResponseMessageType.SUCCESS:
                return  toastr.success(responseMessage.message);
            case ResponseMessageType.WARNING:
                return  toastr.warning(responseMessage.message);
            case ResponseMessageType.ERROR:
                return  toastr.error(responseMessage.message);
            default:
                return  toastr.info(responseMessage.message);
            }
      }
}