import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParameterService {
  private urlSuffixPath = '/parameter';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private transferHttp: TransferState,
    private http: HttpClient
){
}

  getDynamicValues(key: string, name: string): Observable<any> {
    const url = `${environment.serviceApiUrl}${this.urlSuffixPath}/get-value-as-json?name=${name}&key=${key}`;
    return this.http.get(url);
  }
}
