
export const environment = {
  production: true,
  domain:'https://www.halkaarz.info',
  serviceApiUrl: 'https://www.halkaarz.info/api/v1',
  facebookUrl:'https://www.facebook.com/halkaarzinfo',
  instagramUrl:'https://www.instagram.com/halkaarzinfo',
  twitterUrl:'https://twitter.com/halkaarzinfo',
  linkedinUrl:'https://tr.linkedin.com/company/halkaarzinfo',
  youtubeUrl:'https://www.youtube.com/channel/UCYZUhPIv62I14l0JhNHnSSQ',
  webwikiUrl:'https://www.webwiki.com/halkaarz.info',
  logoUrl: 'https://www.halkaarz.info/tr/logo.webp',
  defaultLanguage: 'tr',
  defaultRegion: 'www',
  name: "Halkaarz.info",
  phone: "+908504746675",
  userWebPushInfo: 'http://localhost:8081/api/v1/user-web-push-info',
  webPushPublicKey:"BNRbXIQ55WOUDkPpJJ98va1gZ6fLpyGWNQZpXV2bk1medfR0WNozSE0pyd30X2t8lmUkUmFF1Ho0lC3HQxcOWx4",
  webPushPrivateKey:"VlXeWhd2EjYcgYBbfTItVxzgtDSK34bydzkSWZmw2AA"
};
