import { Component, OnInit ,PLATFORM_ID, Inject, ViewChild, ElementRef, ViewEncapsulation, OnDestroy, LOCALE_ID } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { LangType } from 'src/app/core/enums/lang-type.enum';
import { Utils } from 'src/app/core/utils/utils';
import { Router } from '@angular/router';
import { OptionService } from 'src/app/core/services/option.service';
import { faInfo, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-header',
  encapsulation:ViewEncapsulation.None,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,OnDestroy{
  domainURL=environment.domain+"/"+(this.localId+"").substring(0,2)+"/";
  faInfo= faInfo;
  faSearch=faSearch;
  langType = LangType;
  langTypeArray = Utils.getMKeys(LangType);
  lang:any;
  facebookUrl:string;
  instagramUrl:string;
  twitterUrl:string;
  linkedinUrl:string;
  youtubeUrl:string;
  webwikiUrl: string;
  destroy$: Subject<boolean> =new Subject<boolean>();

  constructor(
    public translate: TranslateService,
    public ngbModal: NgbModal,
    public router: Router,
    @Inject(LOCALE_ID) private localId: Object,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    public optionService: OptionService,
    ) { }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    this.facebookUrl=environment.facebookUrl;
    this.instagramUrl=environment.instagramUrl;
    this.twitterUrl=environment.twitterUrl;
    this.linkedinUrl=environment.linkedinUrl;
    this.youtubeUrl=environment.youtubeUrl;
    this.webwikiUrl=environment.webwikiUrl;
 if (isPlatformBrowser(this.platformId)) {
      this.lang=localStorage.getItem("poptionplang");
   }
  }
  clickme(search:string) {
    this.router.navigate(['/search/', search]);
    this.ngbModal.dismissAll()
  }
  switchLang(lang: any) {
    this.translate.use(lang);
    this.translate.setDefaultLang(lang);
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem("poptionplang",lang);
      this.document.documentElement.lang = lang;

      let loc=this.getLocation();
      let path=loc.pathname.substring(3)
      let newUrl=loc.href.replace(loc.pathname,"")+"/"+lang+path;
      window.location.href=newUrl;
   }
  }

  openPopup(template,size='lg') {
    this.ngbModal.open(template, { size: size });
  }

  keytabSearchContent(event) {
    if(event.target.value.length<2){
      return
    }
      event.target.value.replace(/  +/g, "-")

    this.router.navigate(['/search/', event.target.value]);
    this.ngbModal.dismissAll()
  }
  sendData(event){

  }

  getLocation(): Location {
    return this.document.location;
  }

}
