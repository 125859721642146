import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ArbitrageResolver } from '../core/resolver/arbitrage-resolver';
import { DividendCalculatorResolver } from '../core/resolver/dividend-calculator.resolver';
import { PriorityCalculateResolver } from '../core/resolver/priority-calculate-resolver';
import { AcidTestRatioComponent } from './acid-test-ratio/acid-test-ratio.component';
import { ArbitrageComponent } from './arbitrage/arbitrage.component';
import { CalculateComponent } from './calculate.component';
import { CashRatioComponent } from './cash-ratio/cash-ratio.component';
import { CurrentRatioComponent } from './current-ratio/current-ratio.component';
import { EvenDistributionComponent } from './even-distribution/even-distribution.component';
import { ProportionalDistributionComponent } from './proportional-distribution/proportional-distribution.component';
import { LeverageRatioComponent } from './leverage-ratio/leverage-ratio.component';
import { PriorityCalculateComponent } from './priority-calculate/priority-calculate.component';
import { WrapperCalculateComponent } from './wrapper-calculate/wrapper-calculate.component';
import {BondCalculateComponent} from "./bond-calculate/bond-calculate.component";
import { FibonacciCalculatorResolver } from '../core/resolver/fibonacci-calculator.resolver';
import { FibonacciCalculateComponent } from './fibonacci/fibonacci-calculate/fibonacci-calculate.component';



const routes1: Routes = [
  { path: '', component: CalculateComponent ,
  children: [
    { path: 'arbitraj-hesaplama',resolve: { arbitrageResolver: ArbitrageResolver  },  component: ArbitrageComponent},
    { path: 'asit-test-orani-hesaplama',resolve: { dividendCalculatorResolver: DividendCalculatorResolver  },  component: AcidTestRatioComponent},
    { path: 'bedelli-sermaye-artirimi-hesaplama',resolve: { dividendCalculatorResolver: DividendCalculatorResolver  },  component: WrapperCalculateComponent},
    { path: 'bedelsiz-sermaye-artirimi-hesaplama',resolve: { dividendCalculatorResolver: DividendCalculatorResolver  },  component: WrapperCalculateComponent},
    { path: 'cari-oran-hesaplama',resolve: { dividendCalculatorResolver: DividendCalculatorResolver  },  component: CurrentRatioComponent},
    { path: 'esit-dagitim-hesaplama',resolve: { dividendCalculatorResolver: DividendCalculatorResolver  },  component: EvenDistributionComponent},
    { path: 'kaldirac-orani-hesaplama',resolve: { dividendCalculatorResolver: DividendCalculatorResolver  },  component: LeverageRatioComponent},
    { path: 'nakit-orani-hesaplama',resolve: { dividendCalculatorResolver: DividendCalculatorResolver  },  component: CashRatioComponent},
    { path: 'nakit-temettu-hesaplama',resolve: { dividendCalculatorResolver: DividendCalculatorResolver  },  component: WrapperCalculateComponent},
    { path: 'oransal-dagitim-hesaplama',resolve: { dividendCalculatorResolver: DividendCalculatorResolver  },  component: ProportionalDistributionComponent},
    { path: 'ruchan-hesaplama',resolve: { priorityResolver: PriorityCalculateResolver  },  component: PriorityCalculateComponent},
    { path: 'sermaye-azaltimi-hesaplama',resolve: { dividendCalculatorResolver: DividendCalculatorResolver  },  component: WrapperCalculateComponent},
    { path: 'tahvil-hesaplama',resolve: { dividendCalculatorResolver: DividendCalculatorResolver  },  component: BondCalculateComponent},
    { path: 'fibonacci-hesaplama',resolve: { fibonacciCalculatorResolver: FibonacciCalculatorResolver },  component: FibonacciCalculateComponent},
    { path: '',resolve: { dividendCalculatorResolver: DividendCalculatorResolver  },  component: WrapperCalculateComponent},
  ] }
];
@NgModule({
  imports: [RouterModule.forChild(routes1)],
  exports: [RouterModule]
})
export class CalculateRoutingModule { }
