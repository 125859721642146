import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { environment } from "src/environments/environment";
import { CanonicalService } from "../services/canonical.service";
import { JsonldService } from "../services/jsonld.service";
import { MetaSeoService } from "../services/meta-seo.service";
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class PriorityCalculateResolver implements Resolve<string> {
    constructor(
        private canonicalService: CanonicalService,
        @Inject(LOCALE_ID) private localId: Object,
        public jsonldService: JsonldService,
        private seoService:MetaSeoService,
      ){}
      resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
        const subSuffex=state.url
          const domain=environment.domain;
          const lang=(this.localId+"").substring(0,2);
          let canonicalURL = domain+"/"+lang+subSuffex;
          let domainAndLangURL=domain+"/"+lang;
          let title="Rüçhan Hesaplama | Halka Arz";
         
          let noIndex;
          let description= "Rüçhan Hesaplama, Rüçhan nedir? Rüçhan hesaplama formülü nedir?";
          this.canonicalService.setCanonicalURLForId(canonicalURL);
          this.seoService.setMetaAndTitle(title,description,canonicalURL,domainAndLangURL,noIndex);
          this.canonicalService.setWrapperAlternate(domain,subSuffex);
          this.jsonldService.setOrganization();
          return of(state.url+"");
      }
}
