import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CanonicalService {
  constructor(@Inject(DOCUMENT) private dom) {}

  setCanonicalURL(url?: string) {
    var element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null;
    const canURL = url == undefined ? this.dom.URL : url;
    if (element == null) {
      const link: HTMLLinkElement = this.dom.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.dom.head.appendChild(link);
      link.setAttribute('href', canURL);
    }else{
      element.setAttribute('href', canURL);
    }
  }
  setCanonicalURLForId(canURL?: string) {
    var element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null;
    
    if (element == null) {
      const link: HTMLLinkElement = this.dom.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.dom.head.appendChild(link);
      link.setAttribute('href', canURL);
    }else{
      element.setAttribute('href',canURL);
    }
  }
  setAlternate(url,hreflang) {
    var element: HTMLLinkElement = this.dom.querySelector(`link[rel='alternate'][hreflang='${hreflang}']`) || null;    
    if (element == null) {
      const link: HTMLLinkElement = this.dom.createElement('link');
      link.setAttribute('rel', 'alternate');
      link.setAttribute('hreflang', hreflang);
      this.dom.head.appendChild(link);
      link.setAttribute('href', url);
    }else{
      element.setAttribute('href', url);
    }
  }
  setWrapperAlternate(subUrl,subSuffex){
    this.setAlternate(subUrl+subSuffex,"x-default");
    ([ 'en','tr']).forEach(element => {
      this.setAlternate(subUrl+"/"+element+subSuffex,element);
    });
  }
  
  setOnlyAlternateDefault(url?: string){
    ([ 'en','tr']).forEach(hreflang => {
      var element: HTMLLinkElement = this.dom.querySelector(`link[rel='alternate'][hreflang='${hreflang}']`) || null;  
      if(element){
        element.remove();
      }
    });
    this.setAlternate(url,"x-default");
  }

}
