<div class="card mb-3 ">
    <div class="widget--title">        
        <a class="href" [routerLink]="['/dividend']" title="Temettü Hisseleri"><h2 i18n="@@mostRaad" class="info-detail-title">Temettü Takvimi Arşiv</h2></a>
    </div>
    <ul class="list-unstyled m-2">
        <ng-template ngFor let-data [ngForOf]="months" let-i="index" [ngForTrackBy]="trackByFnMonth">
            <li class="list-group-item-light" ><a class="href" [routerLink]="['/dividend/year',toYear,data[1]]" title="{{data[0]}} {{toYear}} Temettü Hisseleri">{{data[0]}}</a></li>
        </ng-template> 
        <li class="list-group-item-light"><a [routerLink]="['/dividend/year',thisYear]" class="href" title="Bu Yıl Temettü Hisseleri" >{{thisYear}} Temettü Hisseleri</a></li>                  
        <li class="list-group-item-light"><a [routerLink]="['/dividend/year',lastYear]" class="href" title="Geçen Seneki Temettü Hisseleri" >{{lastYear}} Temettü Hisseleri</a></li>                      
        <li class="list-group-item-light"><a [routerLink]="['/calculate/nakit-temettu-hesaplama']" class="href" title="Temettü Hesaplama Yapabilirsiniz">Temettü Hesaplama</a></li>
        <li class="list-group-item-light"><a [routerLink]="['/calculate/bedelli-capital-increase-hesaplama']" class="href" title="Bedelli Sermaye Artırımı Hesaplama">Bedelli Sermaye Hesaplama</a></li>
        <li class="list-group-item-light"><a [routerLink]="['/calculate/bedelsiz-capital-increase-hesaplama']" class="href" title="Bedelsiz Sermaye Artırımı Hesaplama">Bedelsiz Sermaye Hesaplama</a></li>
        <li class="list-group-item-light"><a [routerLink]="['/calculate/sermaye-azaltimi-hesaplama']" class="href" title="Sermaye Azaltımı Hesaplama Yapabilirsiniz">Sermaye Azaltımı Hesaplama</a></li>
    </ul>
</div>