<div id="wrapper" class="wrapper clearfix">
  <section id="tabs" class="project-tab tab-edit">
    <div class="container pairblank">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-8 mb-30-xs mb-30-sm">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">

              <div class="container pairblank">
                <div class="row mb-3" style="padding: 15px;">
                  <h1 class='mb-35'><a [routerLink]="['/calculate/fibonacci-hesaplama']" title="Fibonacci Hesaplama" >Fibonacci Hesaplama</a></h1>
                  <form [formGroup]="uptrendForm" (ngSubmit)="onSubmitUptrendForm()" novalidate>
                    <h3>Yükselen Trend</h3>
                    <div class="error-message" id="error-div" *ngIf="uptrendForm.hasError('highLowMismatchUptrend') && uptrendForm.get('high').touched">
                      Yüksek değer düşük değerden büyük olmalıdır.
                    </div>
                    <p>Yüksek (b):</p>
                    <input type="number" formControlName="high" class="form-control mt-3 mb-3"
                      placeholder="Yüksek (b) Değerini Giriniz" aria-describedby="basic-addon2">
                    <p>Düşük (a):</p>
                    <input type="number" formControlName="low" class="form-control mt-3 mb-3"
                      placeholder="Düşük (a) Değerini Giriniz" aria-describedby="basic-addon2">
                    <p>Özel (c): </p>
                    <input type="number" formControlName="individual" class="form-control mt-3 mb-3"
                      placeholder="Özel (c) Değerini Giriniz" aria-describedby="basic-addon2">
                    <button class="btn btn-primary mt-3 mb-3" i18n="@@buttonCalculator">Hesapla</button>
                    <div id="fibonacci-table-container">
                      <table class="styled-table">
                        <thead>
                          <tr></tr>
                            <th colspan="2">Trend Dışı</th>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let outOfTrendsUptrendDescription of outOfTrendsUptrendDescriptions; let i = index">
                            <tr>
                              <td class="colored-cell">{{outOfTrendsUptrendDescription}}</td>
                              <td> {{outOfTrendsUptrend[i]}}</td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                      <table class="styled-table">
                        <thead>
                          <tr></tr>
                            <th colspan="2">Uzantılar</th>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let extensionsUptrendDescription of extensionsUptrendDescriptions; let i = index">
                            <tr>
                              <td class="colored-cell">{{extensionsUptrendDescription}}</td>
                              <td> {{extensionsUptrend[i]}}</td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>

                  </form>
                  <form [formGroup]="downtrendForm" (ngSubmit)="onSubmitDowntrendForm()" novalidate>
                    <h3>Düşen Trend</h3>
                    <div class="error-message" id="error-div" *ngIf="downtrendForm.hasError('highLowMismatchDowntrend') && downtrendForm.get('high').touched">
                      Yüksek değer düşük değerden büyük olmalıdır.
                    </div>
                    <p>Yüksek (a):</p>
                    <input type="number" formControlName="high" class="form-control mt-3 mb-3"
                      placeholder="Yüksek (a) Değerini Giriniz" aria-describedby="basic-addon2">
                    <p>Düşük (b):</p>
                    <input type="number" formControlName="low" class="form-control mt-3 mb-3"
                      placeholder="Düşük (b) Değerini Giriniz" aria-describedby="basic-addon2">
                    <p>Özel (c): </p>
                    <input type="number" formControlName="individual" class="form-control mt-3 mb-3"
                      placeholder="Özel (c) Değerini Giriniz" aria-describedby="basic-addon2">
                    <button class="btn btn-primary mt-3 mb-3" i18n="@@buttonCalculator">Hesapla</button>
                    <div id="fibonacci-table-container">
                      <table class="styled-table">
                        <thead>
                          <tr></tr>
                            <th colspan="2">Trend Dışı</th>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let outOfTrendsDowntrendDescription of outOfTrendsDowntrendDescriptions; let i = index">
                            <tr>
                              <td class="colored-cell">{{outOfTrendsDowntrendDescription}}</td>
                              <td> {{outOfTrendsDowntrend[i]}}</td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                      <table class="styled-table">
                        <thead>
                          <tr></tr>
                            <th colspan="2">Uzantılar</th>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let extensionsDowntrendDescription of extensionsDowntrendDescriptions; let i = index">
                            <tr>
                              <td class="colored-cell">{{extensionsDowntrendDescription}}</td>
                              <td> {{extensionsDowntrend[i]}}</td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>

                  </form>
                  <div class="widget market-pricing market-list card mb-3" style="position: relative; padding:10px;">
                    <div class="row mt-3">
                      <div class="col-4" style="width:auto;height:auto;">
                        <picture>
                          <source media="(max-width: 90px)" srcset="fibonacci.webp" />
                          <source media="(min-width: 90px)" srcset="fibonacci.webp" />
                          <img loading="lazy" width="90px" height="60px" src="fibonacci.webp"
                            alt="Fibonacci Nedir" title="Fibonacci Nedir">
                        </picture>
                      </div>
                      <div class="col-8">
                        <h3 class="h5"><strong>Fibonacci Hesap Makinası Nedir?</strong></h3> Fibonacci Hesap Makinesi, yatırımcıların Fibonacci geri çekilmelerini ve genişlemelerini hesaplamalarına yardımcı olmaktadır.
                      </div>
                      <div class="w-100"></div>
                      <br>
                      <p>Örnek olarak, eğer EUR/USD’de yukarı yönlü trendde 1,0800 ve 1,1000 seviyesinin arasında Fibo hesaplamak istiyorsanız bu seviyeleri Düşük ve Yüksek alanlarına girerek Fibonacci'ye göre muhtemel geri çekilme noktalarını görebilirsiniz. Özel alan, sadece “Genişleme” hesaplamasında kullanılır.</p>
                      <p>Grafik zaten tamamlanmış bir geri çekilme hareketi gösteriyorsa (diyelim ki piyasa 1,0800’den 1,1000 seviyesine yükseldi ve sonra 1,1000’den 1,0850’ye geriledi ve yeniden yükselmeye başladı), bu durumda “Özel” bölümüne ‘dönüş noktasını’ (1,0850) girebilirsiniz. Böylece olası genişleme oranlarını görebilirsiniz (piyasanın yukarı yönlü piyasaya genişleyeceği durumlarda). Örneğin, Fibonacci Genişlemesine göre 200%’lük genişleme 1,1250 seviyesine ulaşır
                      <p>Aynı şey aşağı yönlü trendlerde de uygulanır (örneğin piyasa 1,1000’den 1,0800’e gerilerse)</p>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4">
          <app-draft-calculate></app-draft-calculate>
          <app-draft-dividend [toYear]="toYear"></app-draft-dividend>
          <app-summary-info></app-summary-info>
          <app-summary-analysis [imageStatus]="true" [count]="5"></app-summary-analysis>
        </div>
      </div>
    </div>
  </section>
  <div id="back-to-top" class="backtop">
    <fa-icon [icon]="faArrUp"></fa-icon>
  </div>
</div>
