
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Page } from 'src/app/core/models/page';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UserWebPushInfoServiceService } from 'src/app/core/services/user-web-push-info-service.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SwPush } from '@angular/service-worker';
import { UserWebPushInfo } from 'src/app/core/models/user-web-push-info';
import { User } from 'src/app/core/models/user';
import { first, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { UserService } from 'src/app/core/services/user.service';



@Component({
  selector: 'app-web-push',
  templateUrl: './web-push.component.html',
  styleUrls: ['./web-push.component.scss']
})

export class WebPushComponent implements OnInit,OnDestroy {

  userWebPushInfos: UserWebPushInfo[];
  page: Page;
  sorts = new Array();
  user: User=new User();
  subscription:Boolean=false;
  endPoint:string;
  deviceInfo = null;
  isBrowser: boolean;
  denied: boolean;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private toastr: ToastrService,
    public translate: TranslateService,
    private userWebPushInfoService:UserWebPushInfoServiceService,
    public userService: UserService,
    private deviceService: DeviceDetectorService,
    private swPush:SwPush) { }

    ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.isBrowser=isPlatformBrowser(this.platformId);
    if (this.isBrowser && 'PushManager' in window) {
      this.denied = Notification?.permission === 'denied';
      this.configFingerPrint ();
      this.subscribeToNotification();
    }
  }

  configFingerPrint() {
    if (isPlatformBrowser(this.platformId)) {
      const fingerprint = JSON.stringify(this.deviceService.getDeviceInfo());
      this.user.fingerprintid=fingerprint;
      if(!localStorage?.getItem('halkaarz_userinfo')){
        this.userService.checkFingerPrint(fingerprint).then((r) => {
          if(!r&&localStorage?.getItem('halkaarz_userinfo')==null){
          let user=new User()
          user.fingerprintid=fingerprint;
          this.userService.save(user).pipe(take(1))
          .subscribe({
            next: () => {
            },error: (err: any) => {
              this.toastr.error(this.translate.instant("message.error.general.save"));
              //console.log(err);
            }
          })
          }
          })
          .catch((error) => {
            //console.log("error: ",error);
          });
        }else{
          this.user=JSON.parse(localStorage?.getItem('halkaarz_userinfo')||null);
        }
      }
    }



  private async subscribeToNotification() {
    const notificationPermission = await Notification.requestPermission();
    const registration = await navigator.serviceWorker.getRegistration();
    const serviceWorker=await navigator.serviceWorker.ready;
    const clientId=await serviceWorker.pushManager.subscribe({
      userVisibleOnly:true,
      applicationServerKey:`${environment.webPushPublicKey}` //todo set public key
    });

    try {
      this.swPush.notificationClicks.subscribe( event => {
        const url = event.notification.data.url;
        window.open(url, '_blank');
      });
      this.swPush.isEnabled
      const sub = this.swPush.requestSubscription({
        serverPublicKey: `${environment.webPushPublicKey}`, //TODO SET PUBLIC KEY

      }).then (sub1 => {
        let  userWebPushInfo=new UserWebPushInfo() ;
        userWebPushInfo.endpoint=sub1.endpoint;
        this.endPoint=sub1.endpoint;
        userWebPushInfo.publicKey=btoa(String.fromCharCode.apply(null, new Uint8Array(sub1.getKey('p256dh'))));
        userWebPushInfo.auth=btoa(String.fromCharCode.apply(null, new Uint8Array(sub1.getKey('auth')))); //TODO AUTH?
        userWebPushInfo.permission=true;
        userWebPushInfo.userAgent = JSON.stringify(this.deviceService.getDeviceInfo());
        userWebPushInfo.browser = this.deviceService.browser;
        userWebPushInfo.os = this.deviceService.os;
        userWebPushInfo.deviceType = this.deviceService.deviceType;
        this.userWebPushInfoService.save(userWebPushInfo).pipe(first())
        .subscribe (
          data => {
            data;
          },
          error => {
            //console.log (error)
          })

          //console.log("Notification subscribe"+sub1);
      })
      .catch (error => {
        //console.error("Notificationlara baglanamadı"+error);
      })
    }

    catch (error) {
      //console.error('Could not subscribe due to:', error);
    }
  }

  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
  }
}

