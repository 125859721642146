<form [formGroup]="contactForm" (ngSubmit)="onSubmit()" novalidate>   
	<div class="mb-3">
		<label for="name" class="form-label" i18n="@@labelName">Ad</label>
		<input type="text" formControlName="name" class="form-control" placeholder="Ad" i18n-placeholder="Ad" minlength="2" maxlength="100"
		[ngClass]="{ 'is-invalid': submitted && contactForm?.controls['name']?.errors }">
		<div *ngIf="submitted &&contactForm?.controls['name']?.errors?.required" class="invalid-feedback" style="display: grid">
			<div>{{ 'general.option.character.not.null' | translate }} </div>
		</div>
		<div *ngIf="submitted &&contactForm?.controls['name']?.errors?.minlength">
			{{ 'general.option.character.least' | translate:'{ character: 2}'}}
		</div> 
		<div *ngIf="submitted &&contactForm?.controls['name']?.errors?.maxlength"> 
			{{ 'general.option.character.max' | translate:'{ character: 100}'}}
		</div>	
	</div>
	<div class="mb-3">
		<label for="email" class="form-label"  i18n="@@labelEmail">Email</label>
		<input type="email"  formControlName="email" class="form-control" placeholder="Email" i18n-placeholder="Email" minlength="2" maxlength="100"
		[ngClass]="{ 'is-invalid': submitted && contactForm?.controls['email']?.errors }">
		<span class="error" *ngIf="email.invalid && email.touched">email is invalid</span> 
		<div *ngIf="submitted &&contactForm?.controls['email']?.errors?.required" class="invalid-feedback" style="display: grid">
			<div>{{ 'general.option.character.not.null' | translate }} </div>
		</div>
		<div *ngIf="submitted &&contactForm?.controls['email']?.errors?.minlength">
			{{ 'general.option.character.least' | translate:'{ character: 2}'}}
		</div> 
		<div *ngIf="submitted &&contactForm?.controls['email']?.errors?.maxlength"> 
			{{ 'general.option.character.max' | translate:'{ character: 100}'}}
		</div>	
	</div>
	<div class="mb-3">
		<label for="contactText" class="form-label" i18n="@@labelMessage">Mesajınız</label>
		<textarea formControlName="message" class="form-control" rows="3" placeholder="Mesajınız" i18n-placeholder="labelMessage"
		[ngClass]="{ 'is-invalid': submitted && contactForm.controls['message'].errors }"></textarea>
        <div *ngIf="submitted && contactForm?.controls['message']?.errors?.required" class="invalid-feedback" style="display: grid">
            <div>{{ 'general.option.character.not.null' | translate }}</div>
        </div>
        <div *ngIf="submitted &&contactForm?.controls['message']?.errors?.minlength">
            {{ 'general.textarea.character.least' | translate:'{ character: 10}'}}
        </div> 
        <div *ngIf="submitted &&contactForm?.controls['message']?.errors?.maxlength"> 
            {{ 'general.textarea.character.max' | translate:'{ character: 250}'}}
        </div>
	</div>
	<button class="btn btn-primary" i18n="@@buttonSend">Gönder</button>
</form>