<div id="wrapper" class="wrapper clearfix">
  <section id="tabs" class="project-tab tab-edit">
    <div class="container pairblank">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-8 mb-30-xs mb-30-sm">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="container pairblank">
                <div class="row mb-3" style="padding: 15px;">
                  <form [formGroup]="leverageRatioForm" (ngSubmit)="onSubmit()" novalidate>
                    <h1><a [routerLink]="['/calculate/kaldirac-orani-hesaplama']"
                        title="Finansal Kaldıraç Oranı Hesaplama">Finansal Kaldıraç Oranı
                        Hesaplama</a></h1>
                    <p>Toplam Yükümlülükler:</p>
                    <input type="number" formControlName="totalDebt" class="form-control mt-3 mb-3"
                      placeholder="Toplam Yükümlülük Giriniz" aria-describedby="basic-addon2">
                    <p>Toplam Varlıklar:</p>
                    <input type="number" formControlName="totalAssets" class="form-control mt-3 mb-3"
                      placeholder="Toplam Varlık Giriniz" aria-describedby="basic-addon2">
                    <label class='mt-3 mb-3' *ngIf="result" style="display:block">Sonuç: %{{result}}</label>
                    <button class="btn btn-primary mt-3 mb-3" i18n="@@buttonCalculator">Hesapla</button>
                  </form>
                  <div class="widget market-pricing market-list card mb-3" style="position: relative; padding:10px;">
                    <div class="row">
                      <div class="justify-content">
                        <picture>
                          <source media="(max-width: 90px)" srcset="{{domainURL}}finansal-kaldirac-orani.webp" />
                          <source media="(min-width: 90px)" srcset="{{domainURL}}finansal-kaldirac-orani.webp" />
                          <img loading="lazy" src="{{domainURL}}finansal-kaldirac-orani.webp"
                            alt="Finansal Kaldıraç Oranı" title="Finansal Kaldıraç Oranı">
                        </picture>
                      </div>
                      <div>
                        <h3 class="h5"><strong>Finansal Kaldıraç Oranı Nedir?</strong></h3> Finansal kaldıraç oranı aynı
                        zamanda sadece “kaldıraç oranı” olarakta isimlendirilebilir.
                      </div>
                      <div class="w-100"></div>
                      <br>
                      <p>Şirketin Varlıkların yüzde kaçının yabancı kaynaklar tarafından finanse edildiğini
                        göstermektedir. bu oran işletmenin bankalar gibi kredi kuruluşları tarafından borçların geri
                        ödenme riskinin değerlendirilmesi yapılırken kullanılan bir orandır, bu oranın genellikle %50
                        den az olması istenmektedir. Bu oranın %50 den fazla olması durumunda işletmenin finansmanında,
                        yabancı kaynak kullanımının gereğinden fazla olduğu, borçlarını ve bu borçların faizlerinin
                        ödenmesinde güçlüklerle karşılaşılabileceğini şeklinde yorumlanmaktadır.</p>
                      <p>Not: Oranlardaki ideal aralıklar ülkeden ülkeye, sektörden sektöre değişiklik
                        gösterebilmektedir. Yatırımcıların bu değerleri kontrol ettikleri zamanlarda sektör
                        ortalamalarını da göz önünde bulundurmaları daha sağlıklı sonuçlar elde etmelerine olanak
                        sağlayacaktır.</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4">
          <app-draft-calculate></app-draft-calculate>
          <app-draft-dividend [toYear]="toYear"></app-draft-dividend>
          <app-summary-info></app-summary-info>
          <app-summary-analysis [imageStatus]="true" [count]="5"></app-summary-analysis>
        </div>
      </div>
    </div>
  </section>
  <div id="back-to-top" class="backtop">
    <fa-icon [icon]="faArrUp"></fa-icon>
  </div>