import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserWebPushInfo } from '../models/user-web-push-info';
import { Observable } from 'rxjs';
import { Page } from '../models/page';
import { SortData } from '../models/sort-data';
import { PagedData } from '../models/paged-data';


@Injectable({
  providedIn: 'root'
})

export class UserWebPushInfoServiceService {
  pagedDataAny: any;
  urlSuffixPath="/user-web-push-info";
  constructor(private http: HttpClient) { }

  save(userWebPushInfo: UserWebPushInfo):Observable<Object> {
    return this.http.post(`${environment.serviceApiUrl}${this.urlSuffixPath}/save`, userWebPushInfo);
  }

  getListFilter(filter:UserWebPushInfo): Observable<UserWebPushInfo[]> {
    let postUrl = `${environment.serviceApiUrl}${this.urlSuffixPath}` + "/list-filter";
    this.pagedDataAny = this.http.post(postUrl, filter);
    return this.pagedDataAny;
  }

  getPageAbleList(page: Page, sortData: SortData<UserWebPushInfo>): Observable<PagedData<UserWebPushInfo>> { //TODO SORTDATA??
    let postUrl = `${environment.serviceApiUrl}${this.urlSuffixPath}` + "/pageable-list?pageSize=" + page.size + "&page="  + (page.pageNumber + 1) ;
    this.pagedDataAny = this.http.post(postUrl, sortData);
    return this.pagedDataAny;
  }
}


