import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidatorFn, AbstractControl  } from '@angular/forms';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { ParameterService } from 'src/app/core/services/parameter.service';

@Component({
  selector: 'app-fibonacci-calculate',
  encapsulation:ViewEncapsulation.None,
  templateUrl: './fibonacci-calculate.component.html',
  styleUrls: ['./fibonacci-calculate.component.scss']
})
export class FibonacciCalculateComponent implements OnInit {
  submitted: boolean;
  uptrendForm: FormGroup;
  downtrendForm: FormGroup;
  faArrUp=faArrowUp;

  outOfTrendsUptrend: number[] = [];
  extensionsUptrend: string[] = [];
  outOfTrendsUptrendDescriptions: string[] = [];
  extensionsUptrendDescriptions: string[] = [];

  outOfTrendsDowntrend: number[] = [];
  extensionsDowntrend: string[] = [];
  outOfTrendsDowntrendDescriptions: string[] = [];
  extensionsDowntrendDescriptions: string[] = [];


  toYear=(new Date()).getFullYear();

  constructor(private parameterService: ParameterService) {
    this.outOfTrendsUptrendDescriptions = ['0% (b)', '23,6%', '38,2%', '50%', '61,8%', '76,4%', '100% (a)', '138,2%']
    this.extensionsUptrendDescriptions = ['261,8%', '200%', '161,8%', '138,2%', '100%', '61,8%', '50%', '38,2%','23,6%']
    this.outOfTrendsDowntrendDescriptions = ['138,2%', '100% (a)', '76,4%', '61,8%', '50%', '38,2%', '23,6%', '0% (b)']
    this.extensionsDowntrendDescriptions = ['23,6%', '38,2%', '50%', '61,8%', '100%', '61,8%', '50%', '38,2%','23,6%']
  }

  ngOnInit(): void {
    this.uptrendForm = this.createFormGroupUptrend()
    this.downtrendForm = this.createFormGroupDowntrend();
  }

  createFormGroupUptrend(): FormGroup {
    return new FormGroup({
      high: new FormControl('', [Validators.required, Validators.minLength(1)]),
      low: new FormControl('', [Validators.required, Validators.minLength(1)]),
      individual: new FormControl('', [Validators.required, Validators.minLength(1)])
    }, { validators: this.highLowValidatorUptrend });
  }

  createFormGroupDowntrend(): FormGroup {
    return new FormGroup({
      high: new FormControl('', [Validators.required, Validators.minLength(1)]),
      low: new FormControl('', [Validators.required, Validators.minLength(1)]),
      individual: new FormControl('', [Validators.required, Validators.minLength(1)])
    }, { validators: this.highLowValidatorDowntrend });
  }

  highLowValidatorUptrend: ValidatorFn = (control: AbstractControl): { [key: string]: boolean } | null => {
    const highValue = control.get('high').value;
    const lowValue = control.get('low').value;
    if (highValue <= lowValue) {
      return { highLowMismatchUptrend: true };
    }
    return null;
  }

  highLowValidatorDowntrend: ValidatorFn = (control: AbstractControl): { [key: string]: boolean } | null => {
    const highValue = control.get('high').value;
    const lowValue = control.get('low').value;
    if (highValue <= lowValue) {
      return { highLowMismatchDowntrend: true };
    }
    return null;
  }

  onSubmitUptrendForm() {
    this.submitted = true;
    if (!this.uptrendForm.invalid) {
      const b = this.uptrendForm.controls["high"].value
      const a = this.uptrendForm.controls["low"].value
      let c = this.uptrendForm.controls["individual"].value

      const key = 'fibonacciParameters';
      const name = 'FIBONACCI_PARAMETERS';
      const rets = [];
      const exts = [];

      this.parameterService.getDynamicValues(key, name).subscribe(
        (fibonacciRatios: any) => {
          if (b > a) {
            var pips = b - a;
            for (let i=0; i<7; i++) {
              rets.push((b - (pips * fibonacciRatios[i])).toFixed(3))
            }
            for (let i=7; i<16; i++) {
              exts.push ((b + (pips * fibonacciRatios[i])).toFixed(3))
            }

            if (typeof c === 'number') {
              c = c * 1;
              for (let i=16; i<25; i++) {
                exts[i-16] =  (c + (pips * fibonacciRatios[i])).toFixed(3)
              }
            }
          }
          this.outOfTrendsUptrend = [b ,rets[0], rets[1], rets[2], rets[3], rets[4], rets[5], rets[6]];
          this.extensionsUptrend = [exts[0], exts[1], exts[2], exts[3], exts[4], exts[5], exts[6], exts[7],exts[8]]

        },
        (error) => {
          console.error('Error fetching dynamic values', error);
        }
      );
    }else{
      return;
    }
  }

  onSubmitDowntrendForm() {
    this.submitted = true;
    if (!this.downtrendForm.invalid) {
      const a = this.downtrendForm.controls["high"].value
      const b = this.downtrendForm.controls["low"].value
      let c = this.downtrendForm.controls["individual"].value

      const key = 'fibonacciParameters';
      const name = 'FIBONACCI_PARAMETERS';
      const rets=[];
      const exts=[];
      this.parameterService.getDynamicValues(key, name).subscribe(
        (fibonacciRatios: any) => {

          if (a > b) {
            var pips = a- b;
            for (let i=0; i<7; i++) {
              rets.push((b + (pips * fibonacciRatios[i])).toFixed(3))
            }
            for (let i=7; i<16; i++) {
              exts.push ((b - (pips * fibonacciRatios[i])).toFixed(3))
            }
            if (typeof c === 'number') {
              c= c * 1;
              for (let i=16; i<25; i++) {
                exts[i-16] =  (c - (pips * fibonacciRatios[i])).toFixed(3)
              }
          }
          }
          this.outOfTrendsDowntrend = [rets[6] ,rets[5], rets[4], rets[3], rets[2], rets[1], rets[0], b];
          this.extensionsDowntrend = [exts[8], exts[7], exts[6], exts[5], exts[4], exts[3], exts[2], exts[1], exts[0]]
        })
    }else{
      return;
    }
  }
}
