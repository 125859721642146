import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-bond-calculate',
  templateUrl:'./bond-calculate.component.html',
  styleUrls: ['./bond-calculate.component.scss']
})
export class BondCalculateComponent implements OnInit {
  bondCalculateForm: FormGroup;
  result: any;
  submitted: boolean;
  faArrUp=faArrowUp;
  toYear=(new Date()).getFullYear();

  constructor() { }

  ngOnInit(): void {
    this.bondCalculateForm = this.createFormGroup()
  }

  createFormGroup(): FormGroup {
    return new FormGroup({
      bondPrice: new FormControl('',[Validators.required, Validators.minLength(1)]),
      annualCouponPayment: new FormControl('',[Validators.required, Validators.minLength(1)])
    })
  }

  onSubmit() {
    this.submitted = true;
    if (!this.bondCalculateForm.invalid) {
      this.result=this.bondCalculateForm.controls["annualCouponPayment"].value/this.bondCalculateForm.controls["bondPrice"].value
      this.result=this.result.toFixed(5);
    }else{
      return;
    }
  }

}
