import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { Analysis } from 'src/app/core/models/analysis';
import { Page } from 'src/app/core/models/page';
import { SortData } from 'src/app/core/models/sort-data';
import { AnalysisService } from 'src/app/core/services/analysis.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-summary-analysis',
  encapsulation:ViewEncapsulation.None,
  templateUrl: './summary-analysis.component.html',
  styleUrls: ['./summary-analysis.component.scss']
})
export class SummaryAnalysisComponent implements OnInit,OnDestroy {
  domainURL=environment.domain+"/"+(this.localId+"").substring(0,2)+"/";
  analysisList:Analysis[];
  isBrowser: boolean=false;
  @Input('imageStatus') imageStatus: boolean;
  @Input('count') count: number;
  destroy$: Subject<boolean> =new Subject<boolean>();

  constructor(
    @Inject(LOCALE_ID) private localId: Object,
    @Inject(PLATFORM_ID) private platformId: Object,
    public router: Router,
    public analysisService: AnalysisService) { }

  ngOnInit(): void {
    this.isBrowser=isPlatformBrowser(this.platformId);
    if (this.isBrowser||isPlatformServer(this.platformId)) {
      this.getAnalysis();
    }
  }

  ngOnDestroy(): void{
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getAnalysis() {
    let sortData=new SortData<Analysis>();
    let analysis = new Analysis();
    analysis.country="tr";
    analysis.lang="tr";
    sortData.filter=analysis;
    sortData.sorts=[{"dir": "DESC", "prop": "createDate"}];
    let pageInfo = new Page();
    pageInfo.pageNumber = 0;
    pageInfo.size = this.count?this.count:5;
    this.analysisService.getPageAbleList(pageInfo,sortData).pipe(takeUntil(this.destroy$)).subscribe(pagedData => {
      this.analysisList = pagedData.data;
    },
    error => {
      console.log("error: ",error);
    });
  }

public trackByFnA(index, item) {
    if (!item) return null;
    return item.id;
  }
}
