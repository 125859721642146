<div id="wrapper" class="wrapper clearfix">
  <section id="tabs" class="project-tab tab-edit">
    <div class="container pairblank">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-8 mb-30-xs mb-30-sm">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">

              <div class="container pairblank">
                <div class="row mb-3" style="padding: 15px;">
              <form [formGroup]="arbitrajForm"  (ngSubmit)="onSubmit()" novalidate>
                <h1><a [routerLink]="['/calculate/arbitraj-hesaplama']" title="Arbitraj Hesaplama" >Arbitraj Hesaplama</a></h1>

                <p>İlk Değeri Giriniz:</p>
                <input type="number" formControlName="firstValue" class="form-control mt-3 mb-3"
                placeholder="Please Enter First Value" aria-describedby="basic-addon2">
                <p>İkinci Değeri Giriniz:</p>
                <input type="number" formControlName="secondValue" class="form-control mt-3 mb-3"
                placeholder="Please Enter Second Value" aria-describedby="basic-addon2">
                <label class='mt-3 mb-3' *ngIf="result" style="display:block">Sonuç: {{ result| currency:'TRY'}}</label>
                <button class="btn btn-primary mt-3 mb-3" i18n="@@buttonCalculator">Hesapla</button>
              </form>
              </div><div class="col-4" style="width:auto;height:auto;">
                <picture>
                  <source media="(max-width: 90px)" srcset="arbitraj-hesaplama.webp" />
                  <source media="(min-width: 90px)" srcset="arbitraj-hesaplama.webp" />
                  <img loading="lazy" width="90px" height="60px" src="arbitraj-hesaplama.webp"
                    alt="Arbitraj Hesaplama" title="Arbitraj Hesaplama">
                </picture>
              </div>
              <div class="col-8">
                <h3 class="h3"><strong>Arbitraj Nedir?</strong></h3> <p>Fiyatlardaki oynaklıklardan kazanç elde etmek için kullanılan bir yöntemdir. Herhangi bir finansal ürünün farklı borsalardan eş zamanlı alım satımının gerçekleştirerek borsalar arasında farklı fiyatlarda olan ürünlerde daha uygun olan yerden alarak daha yüksek fiyatlı olan borsada satılması işlemidir. </p>
                <h4>Arbitraj İşlemi Yapılan Yatırım Ürünleri Nelerdir?</h4>
                <p>Arbitraj işlemi, günümüzde daha çok altın ve döviz piyasasında yapılmaktadır. Her ülkenin birden fazla altın ve döviz borsası olduğundan dolayı fiyat dengesizlikleri oluşabilmektedir. Bu tür durumda yatırımcılar arbitraj işlemleri gerçekleştirerek kazançlar sağlayabilmekte. Ülkeler arası borsalardaki fiyat dengesizliğinden de arbitraj imkânları oluşabilmektedir. </p>
              </div>
              </div>


            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4">
          <app-draft-calculate></app-draft-calculate>
          <app-draft-dividend [toYear]="toYear"></app-draft-dividend>
          <app-summary-info></app-summary-info>
          <app-summary-analysis [imageStatus]="true" [count]="5"></app-summary-analysis>
        </div>
      </div>
    </div>
  </section>
  <div id="back-to-top" class="backtop">
    <fa-icon [icon]="faArrUp"></fa-icon>
  </div>
</div>