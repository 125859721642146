import { Component, Inject, Input, LOCALE_ID, OnInit, ViewEncapsulation } from '@angular/core';
import { Utils } from 'src/app/core/utils/utils';

@Component({
  selector: 'app-draft-dividend',
  encapsulation:ViewEncapsulation.None,
  templateUrl: './draft-dividend.component.html',
  styleUrls: ['./draft-dividend.component.scss']
})
export class DraftDividendComponent implements OnInit {
  @Input('toYear') toYear: number;
  lang:string=(this.localId+"").substring(0,2);
  date = new Date();
  months=Utils.getMKeys(Utils.monthStrings);
  lastYear: number;
  thisYear=(new Date()).getFullYear();
  constructor(
    @Inject(LOCALE_ID) private localId: Object,
  ) { }

  ngOnInit(): void {
    this.months=this.lang=='tr'?Utils.getMKeys(Utils.monthStrings):Utils.getMKeys(Utils.monthStringsEn)
    this.toYear=this.toYear?this.toYear:(new Date()).getFullYear();
    this.lastYear=this.toYear-1;
  }
  
  public trackByFnMonth(index, item) {
    if (!item) return null;
    return item.id;
  }
}
