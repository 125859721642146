import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CanonicalService } from '../services/canonical.service';
import { JsonldService } from '../services/jsonld.service';
import { MetaSeoService } from '../services/meta-seo.service';

@Injectable({
  providedIn: 'root'
})
export class DividendCalculatorResolver implements Resolve<string> {
  constructor(
    private canonicalService: CanonicalService,
    @Inject(LOCALE_ID) private localId: Object,
    public jsonldService: JsonldService,
    private seoService:MetaSeoService,
  ){}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
    const subSuffex=state.url
      const domain=environment.domain;
      const lang=(this.localId+"").substring(0,2);
      let canonicalURL = domain+"/"+lang+subSuffex;
      let domainAndLangURL=domain+"/"+lang;
      let title="Nakit Temettü Hesaplama | Halka Arz";
      if(state.url=='/calculate/bedelli-capital-increase-hesaplama'){
        title="Bedelli Sermaye Artırımı Hesaplama | Halka Arz";
      }else if(state.url=='/calculate/bedelsiz-capital-increase-hesaplama'){
        title="Bedelsiz Sermaye Artırımı Hesaplama | Halka Arz";
      }else if(state.url=='/calculate/sermaye-azaltimi-hesaplama'){
        title="Sermaye Azaltımı Hesaplama | Halka Arz";
      }else{
        title="Nakit Temettü Hesaplama | Halka Arz";
      }
      
      let noIndex;
      let description= "Temettü Hesaplama " + title;
      this.canonicalService.setCanonicalURLForId(canonicalURL);
      this.seoService.setMetaAndTitle(title,description,canonicalURL,domainAndLangURL,noIndex);
      this.canonicalService.setWrapperAlternate(domain,subSuffex);
      this.jsonldService.removeJsonSnippet();
      this.jsonldService.setOrganization();
      return of(state.url+"");
  }
}
