import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { httpTranslateLoader } from '../core/core.module';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WrapperCalculateComponent } from './wrapper-calculate/wrapper-calculate.component';
import { CalculateInComponent } from './calculate-in/calculate-in.component';
import { CalculateRoutingModule } from './calculate-routing.module';
import { RouterModule } from '@angular/router';
import { CalculateComponent } from './calculate.component';
import { ArbitrageComponent } from './arbitrage/arbitrage.component';
import { LeverageRatioComponent } from './leverage-ratio/leverage-ratio.component';
import { AcidTestRatioComponent } from './acid-test-ratio/acid-test-ratio.component';
import { CashRatioComponent } from './cash-ratio/cash-ratio.component';
import { CurrentRatioComponent } from './current-ratio/current-ratio.component';
import { PriorityCalculateComponent } from './priority-calculate/priority-calculate.component';
import { EvenDistributionComponent } from './even-distribution/even-distribution.component';
import {ProportionalDistributionComponent} from "./proportional-distribution/proportional-distribution.component";
import {BondCalculateComponent} from "./bond-calculate/bond-calculate.component";
import { FibonacciCalculateComponent } from './fibonacci/fibonacci-calculate/fibonacci-calculate.component';

@NgModule({
  declarations: [
    CalculateComponent,
    CalculateInComponent,
    WrapperCalculateComponent,
    ArbitrageComponent,
    PriorityCalculateComponent,
    LeverageRatioComponent,
    AcidTestRatioComponent,
    CashRatioComponent,
    EvenDistributionComponent,
    BondCalculateComponent,
    ProportionalDistributionComponent,
    CurrentRatioComponent,
    FibonacciCalculateComponent],
  imports: [
    RouterModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CommonModule,
    CalculateRoutingModule,
    TranslateModule.forChild({
      loader: {
      provide: httpTranslateLoader,
      useFactory: httpTranslateLoader,
      deps: [HttpClient]
    },
    isolate: false
    }),
  ],
  exports:[CalculateInComponent]
})
export class CalculateModule { }


