import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-draft-calculate',
  templateUrl: './draft-calculate.component.html',
  styleUrls: ['./draft-calculate.component.scss']
})
export class DraftCalculateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
