import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-priority-calculate',
  templateUrl: './priority-calculate.component.html',
  styleUrls: ['./priority-calculate.component.scss']
})
export class PriorityCalculateComponent implements OnInit {

  ruchanForm!: FormGroup;
  submitted = false;
  result:any;

  constructor() { }

  ngOnInit(): void {
    this.ruchanForm = this.createFormGroup()
  }

  createFormGroup(): FormGroup {
    return new FormGroup({
      stockCountValue: new FormControl('',[Validators.required, Validators.minLength(1)]),
      ruchanValue: new FormControl('',[Validators.required, Validators.minLength(1)]),
      bedelliSermayeValue: new FormControl('',[Validators.required, Validators.minLength(1)])
    })
  } 

  onSubmit() {
    this.submitted = true;
    if (!this.ruchanForm.invalid) {
      this.result=this.ruchanForm.controls["stockCountValue"].value*this.ruchanForm.controls["ruchanValue"].value*this.ruchanForm.controls["bedelliSermayeValue"].value;
      this.result=this.result.toFixed(5);
    }else{
      return;
    }
  }

}
