import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-current-ratio',
  templateUrl: './current-ratio.component.html',
  styleUrls: ['./current-ratio.component.scss']
})
export class CurrentRatioComponent implements OnInit {
currentRatioForm: FormGroup;
result: any;
submitted: boolean;
faArrUp=faArrowUp;
toYear=(new Date()).getFullYear();

  constructor() { }

  ngOnInit(): void {
    this.currentRatioForm = this.createFormGroup()
  }

  createFormGroup(): FormGroup {
    return new FormGroup({
      shortTermLiabilities: new FormControl('',[Validators.required, Validators.minLength(1)]),
      currentAssets: new FormControl('',[Validators.required, Validators.minLength(1)])
    })
  }
  
onSubmit() {
  this.submitted = true;
  if (!this.currentRatioForm.invalid) {
    this.result=this.currentRatioForm.controls["currentAssets"].value/this.currentRatioForm.controls["shortTermLiabilities"].value
    this.result=this.result.toFixed(5);
  }else{
    return;
  }
}

}
