import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-cash-ratio',
  templateUrl: './cash-ratio.component.html',
  styleUrls: ['./cash-ratio.component.scss']
})
export class CashRatioComponent implements OnInit {
result: any;
submitted: boolean;
cashRatioForm: FormGroup;
faArrUp=faArrowUp;
toYear=(new Date()).getFullYear();

  constructor() { }

  ngOnInit(): void {
    this.cashRatioForm = this.createFormGroup()
  }

  
  createFormGroup(): FormGroup {
    return new FormGroup({
      shortTermLiabilities: new FormControl('',[Validators.required, Validators.minLength(1)]),
      cashEquivalents: new FormControl('',[Validators.required, Validators.minLength(1)])
    })
  }
  
  onSubmit() {
    this.submitted = true;
    if (!this.cashRatioForm.invalid) {
      this.result=this.cashRatioForm.controls["cashEquivalents"].value/this.cashRatioForm.controls["shortTermLiabilities"].value
      this.result=this.result.toFixed(5);
    }else{
      return;
    }
  }

  

}
