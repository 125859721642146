<div id="wrapper" class="wrapper clearfix">
  <section id="tabs" class="project-tab tab-edit">
      <div class="container pairblank">
          <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-8 mb-30-xs mb-30-sm">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <div class="sectionCenterTitle">
                        <h1 *ngIf="dividendType=='/calculate'||dividendType=='/calculate/nakit-temettu-hesaplama'">Nakit Temettü Hesaplama</h1>
                        <h1 *ngIf="dividendType=='/calculate/bedelli-sermaye-artirimi-hesaplama'">Bedelli Sermaye Artırımı Hesaplama</h1>
                        <h1 *ngIf="dividendType=='/calculate/bedelsiz-sermaye-artirimi-hesaplama'">Bedelsiz Sermaye Artırımı Hesaplama</h1>
                        <h1 *ngIf="dividendType=='/calculate/sermaye-azaltimi-hesaplama'">Sermaye Azaltımı Hesaplama</h1>
                        <h2><span class="visible-title">Temettü Hesaplama </span></h2>
                      </div>
                      <div class="tab-content " id="nav-tabContent">
                        <app-calculate-in></app-calculate-in>
                      </div>
                      <div class="tab-content " id="nav-tabContent">
                        <strong *ngIf="dividendType=='/calculate'||dividendType=='/calculate/nakit-temettu-hesaplama'">Nakit Temettü Hesaplama</strong>
                        <strong *ngIf="dividendType=='/calculate/bedelli-sermaye-artirimi-hesaplama'">Bedelli Sermaye Artırımı Hesaplama</strong>
                        <strong *ngIf="dividendType=='/calculate/bedelsiz-sermaye-artirimi-hesaplama'">Bedelsiz Sermaye Artırımı Hesaplama</strong>
                        <strong *ngIf="dividendType=='/calculate/sermaye-azaltimi-hesaplama'">Sermaye Azaltımı Hesaplama</strong>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4">
                  <app-draft-calculate></app-draft-calculate>
                  <app-draft-dividend [toYear]="toYear"></app-draft-dividend>
                  <app-summary-info></app-summary-info>
                  <app-summary-analysis [imageStatus]="true" [count]="5"></app-summary-analysis>
              </div>
          </div>
      </div>
  </section>
  <div id="back-to-top" class="backtop"><fa-icon [icon]="faArrUp"></fa-icon></div>
</div>