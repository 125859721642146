import { DOCUMENT, isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import { from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Option } from '../models/option';
import { Summary } from '../models/summary';
import { WrapperService } from '../utils/wrapper-service';
@Injectable({
  providedIn: 'root'
})
export class OptionService {

  pagedDataAny: any;
  urlSuffixPath=`${environment.serviceApiUrl}/option`;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private transferHttp: TransferState, 
    private http: HttpClient
){
}

  get(): any {
    return this.http.get<Option>(`${this.urlSuffixPath}/get`);
  }

  getSummary(): Observable<Summary[]> {
    let postUrl = `${this.urlSuffixPath}/summary`;
    return WrapperService.getData(this.transferHttp,this.platformId,postUrl, null, () => {
      return this.http.get<any>(postUrl);
      })   
  }
  
}
