<div class="footer-mr">
    <div class="container">
        <div class="row clearfix">
            <div class="col-sm-12 col-md-2 col-lg-2 footer--widget widget-about">
                <div class="widget-content">
                    <a [routerLink]="['/']">
                    <picture>
                        <source media="(max-width: 800px)" width="90px" height="18px" srcset="{{domainURL}}temettu-hisseleri-takvimi.webp" />
                        <source media="(min-width: 90px)" width="180px" height="36px" srcset="{{domainURL}}temettu-hisseleri-takvimi.webp" />
                        <img src="{{domainURL}}temettu-hisseleri-takvimi.webp" alt="Halka Arz Hisseleri ve Analizler" title="Halka Arz Hisseleri" width="180px" height="36px">
                        <div class="entry--overlay"></div>
                    </picture>
                    </a>
                </div>
            </div>
            <div class="col-sm-12 col-md-2 col-lg-2 footer--widget widget-links">
                <div class="widget-title">
                    <strong class="footer-title" i18n="@@company">Şirket</strong>
                    <div class="widget-content">
                        <div class="footer-global">
                            <div><a [routerLink]="['/terms']" routerLinkActive="active" target="_blank"
                                    i18n="@@about">Hakkımızda</a></div>
                            <div><a href="#" class="contact-link"
                                    onMouseOut="this.style.color='#9b9b9b'"
                                    (click)="openPopup(templateContact);$event.preventDefault()"
                                    i18n="@@contact">İletişim</a></div>
                            <div><a [routerLink]="['/legacy']" routerLinkActive="active" target="_blank"
                                    i18n="@@legacy">Yasal Uyarı</a></div>
                            <div><a [routerLink]="['/privacy']" routerLinkActive="active" target="_blank"
                                    i18n="@@privacy">Kişisel Verilerin Korunması</a></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-2 col-lg-2 footer--widget widget-links">
                <div class="widget-title">
                    <strong class="footer-title" i18n="@@market">Arz ve Temmettü</strong>
                    <div class="widget-content">
                        <div class="footer-global">
                            <div><a [routerLink]="['/']" i18n="@@ipo">Halka Arz</a></div>
                            <div><a [routerLink]="['/ipo-calendar']" i18n="@@ipoCalendar">Arz Takvimi</a></div>
                            <div><a [routerLink]="['/dividend']" i18n="@@dividend">Temettü</a></div>
                            <div><a [routerLink]="['/calculate']" i18n="@@calculate">Hesaplamalar</a></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-2 col-lg-2 footer--widget widget-links">
                <div class="widget-title">
                    <strong class="footer-title" i18n="@@StockRecommendation">Hisse Önerileri</strong>
                    <div class="widget-content">
                        <div class="footer-global">
                            <div><a [routerLink]="['/stock-recommendation']" title="Aracı kurumların hisse hedef fiyatları" i18n="@@BrokerageStockRecommendation">Aracı Kurum Hisse Önerileri</a></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-2 col-lg-2 footer--widget widget-links">
                <div class="widget-title">
                    <strong class="footer-title" i18n="@@blog">Blog</strong>
                    <div class="widget-content">
                        <div class="footer-global">
                            <div><a [routerLink]="['/info']" i18n="@@mustInfo">Gerekli Bilgiler</a></div>
                            <div><a [routerLink]="['/analysis']" i18n="@@analysis">Analiz</a></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-2 col-lg-2 footer--widget widget-links">
                <div class="widget-title">
                    <strong class="footer-title" >Takipte kalın</strong>
                    <div class="widget-content">
                        <div class="footer-global">
                            <div>
                                <a title="Halka Arz Info X" id="twitterUrl_home_id" [href]="twitterUrl"
                                   target="_blank" rel="noreferrer" aria-label="Halka Arz X">
                                    <fa-icon [icon]="faTwitter"></fa-icon> Halka Arz X
                                </a>
                            </div>
                            <div>
                                <a title="Halka Arz Info Instagram" id="instagramUrl_home_id" [href]="instagramUrl"
                                   target="_blank" rel="noreferrer" aria-label="Halka Arz Instagram">
                                    <fa-icon [icon]="faInstagram"></fa-icon> Halka Arz Instagram
                                </a>
                            </div>
                            <div>
                                <a title="Halka Arz Info Facebook" id="facebookUrl_home_id" [href]="facebookUrl"
                                   target="_blank" rel="noreferrer" aria-label="Halka Arz Facebook">
                                    <fa-icon [icon]="faFacebook"></fa-icon> Halka Arz Facebook
                                </a>
                            </div>
                            <div>
                                <a title="Halka Arz Info Linkedin" id="linkedinUrl_home_id" [href]="linkedinUrl"
                                   target="_blank" rel="noreferrer" aria-label="Halka Arz Linkedin">
                                    <fa-icon [icon]="faLinkedin"></fa-icon> Halka Arz Linkedin
                                </a>
                            </div>
                            <div>
                                <a title="Halka Arz Info Youtube" id="youtubeUrl_home_id" [href]="youtubeUrl"
                                   target="_blank" rel="noreferrer" aria-label="Halka Arz Youtube">
                                    <fa-icon [icon]="faYoutube"></fa-icon> Halka Arz Youtube
                                </a>
                            </div>
                            <div>
                                <a title="Halka Arz Info Webwiki" id="webwikiUrl_home_id" [href]="webwikiUrl"
                                   target="_blank" rel="noreferrer" aria-label="Halka Arz Webwiki">
                                    <fa-icon [icon]="faWeebly"></fa-icon> Halka Arz Webwiki
                                </a>
                            </div>
                        </div>
                </div>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</div>
<ng-template #templateContact let-modal>
    <div class="modal-dialog modal-dialog-scrollable" style="max-width: 100%!important; margin: 0px;">
        <div class="modal-content">
            <div class="modal-header">
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
			</div>
            <div class="modal-body">
                <app-contacts></app-contacts>
            </div>
        </div>
    </div>
</ng-template>
