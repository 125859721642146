export class User {
    id:string | undefined;
    fingerprintid:string | undefined;
    name:string | undefined;
    displayName: string | null | undefined;
    email: string | null | undefined;
    phoneNumber: string | null | undefined;
    photoURL: string | null | undefined;
    emailVerified: boolean | undefined;
    status:boolean | undefined;
    createDate:Date | undefined;
    lang:string | undefined;
    country:string | undefined;
}
