
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-calculate',
  encapsulation:ViewEncapsulation.None,
  templateUrl: './calculate.component.html',
  styleUrls: ['./calculate.component.scss']
})
export class CalculateComponent implements OnInit {
  isBrowser: boolean;
  faArrUp=faArrowUp;
  constructor(  
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit(): void {
    this.isBrowser=isPlatformBrowser(this.platformId);
    if (isPlatformBrowser(this.platformId)) {

    }
  }
  
}