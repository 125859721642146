import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import { Contact } from '../core/models/contact';
import { WrapperResponse } from '../core/models/wrapper-response';
import { ContactService } from '../core/services/contact.service';
import { ErrorMsg } from '../core/utils/error-msg';
import { MessageToastrShow } from '../core/utils/message-tastr-show';

@Component({
  selector: 'app-contacts',
  encapsulation:ViewEncapsulation.None,
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit,OnDestroy {
  submitted = false;
  contact:Contact=new Contact();
  contactForm=new FormGroup({email:new FormControl('',[Validators.required,Validators.email])})
  get email(){return this.contactForm.get('email')}
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public translate: TranslateService,
    public contactService: ContactService,
    public router: Router,
    public modalService: NgbModal,
    private toastr:ToastrService

    ) { }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit() {
    this.contactForm = this.createFormGroup()
    this.newObject();


  }

  createFormGroup(): FormGroup {
    return new FormGroup({
      name: new FormControl('',[Validators.maxLength(100)]),
      email: new FormControl('',[Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
      message: new FormControl('',[Validators.required,Validators.minLength(10), Validators.maxLength(250)]),
    })
  }

  onSubmit() {
    this.submitted = true;
    if (this.contactForm.invalid) {
      return;
    }

    this.setFormToObject();
    this.contactService.save(this.contact).pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          let response=(data as WrapperResponse<Contact>)
          this.contact=response.t;
          MessageToastrShow.show(this.toastr,response.responseMessageDto);
        },
        error: error => {
          this.toastr.error(ErrorMsg.getMsg(this.translate,error));
        }});
  }

  newObject() {
    this.contactForm?.reset();
    this.submitted=false;
    this.contact = new Contact();
  }

  setObjectToForm(contact:Contact){
    this.contactForm.controls["name"].setValue(contact.name);
    this.contactForm.controls["email"].setValue(contact.email);
    this.contactForm.controls['message'].setValue(contact.message);
  }

  setFormToObject() {
    this.contact.name=this.contactForm.controls["name"].value;
    this.contact.email=this.contactForm.controls["email"].value;
    this.contact.message=this.contactForm.controls["message"].value;
  }

}
