import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-wrapper-calculate',
  encapsulation:ViewEncapsulation.None,
  templateUrl: './wrapper-calculate.component.html',
  styleUrls: ['./wrapper-calculate.component.scss']
})
export class WrapperCalculateComponent implements OnInit {
  dividendType: any;
  faArrUp=faArrowUp;
  toYear=(new Date()).getFullYear();
  constructor( public router: Router,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.dividendType=data.dividendCalculatorResolver
    })
  }

}
