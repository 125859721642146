import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Contact } from '../models/contact';
import { Page } from '../models/page';
import { PagedData } from '../models/paged-data';
import { SortData } from '../models/sort-data';
import { WrapperService } from '../utils/wrapper-service';
@Injectable({
  providedIn: 'root',
})
export class ContactService {
  pagedDataAny: any;
  serviceApiUrl=`${environment.serviceApiUrl}/news`;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private transferHttp: TransferState, 
    private http: HttpClient
){
}

  getAutoCompleteList(term: string = null): Observable<Contact[]> {
    if (term) {
        let response=this.http.get<any>(`${this.serviceApiUrl}/list_autocomp?term=${term}`).pipe(map(rsp => rsp))
        return response;
    } else {
        return of([]);
    }
  }
  
  getList(): any {
    return this.http.get<Contact[]>(`${this.serviceApiUrl}/list`);
  }

  getPageAbleList(page: Page, sortData: SortData<Contact>): Observable<PagedData<Contact>> {
    let postUrl = `${this.serviceApiUrl}` + "/pageable-list?page=" + (page.pageNumber + 1) + "&pageSize=" + page.size;
    return WrapperService.getData(this.transferHttp,this.platformId,postUrl, sortData, () => {
    return this.http.post(postUrl, sortData);
  })
}

  getByCode(code: string) {
    let postCode=`${this.serviceApiUrl}/getByCode/` + code;
    return WrapperService.getData(this.transferHttp,this.platformId,postCode, null, () => {
      return this.http.get<any>(postCode);
      })  
  }
  getByUrl(url: string) : Observable<Contact[]>{
    let postUrl = `${this.serviceApiUrl}/get?url=${url}`;
    return WrapperService.getData(this.transferHttp,this.platformId,postUrl, null, () => {
      return this.http.get<any>(postUrl);
      })   
  }

  getById(id: string) {
    let postId=`${this.serviceApiUrl}/getById/` + id;
    return WrapperService.getData(this.transferHttp,this.platformId,postId, null, () => {
      return this.http.get<any>(postId);
      })   
  }

  save(contact: Contact):Observable<Object> {
    return this.http.post(`${this.serviceApiUrl}/save`, contact);
  }

  update(contact: Contact) {
    return this.http.put(`${this.serviceApiUrl}/update`, contact);
  }

  delete(id: number):Observable<Object> {
    return this.http.post(`${this.serviceApiUrl}/delete`, id);
  }
}