export class Info {
    title:string | undefined;
    subTitle:string | undefined;
    infos: Array<string> = new Array();
    url:string | undefined;
    imageUrl:string | undefined;
    order:string | undefined;
    lang:string | undefined;
    country:string | undefined;
    commentCount:number | undefined;
    createDate:string | undefined;
    status:Boolean=true;
}
