import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-proportional-distribution',
  templateUrl:'./proportional-distribution.component.html',
  styleUrls: ['./proportional-distribution.component.scss']
})
export class ProportionalDistributionComponent implements OnInit {
  proportionalDistributionForm: FormGroup;
  demandFloor: any;
  result:any;
  supplyRate:any;
  numberOfLotsToBought:any;
  submitted: boolean;
  faArrUp=faArrowUp;
  toYear=(new Date()).getFullYear();

  constructor() { }

  ngOnInit(): void {
    this.proportionalDistributionForm = this.createFormGroup()
  }

  createFormGroup(): FormGroup {
    return new FormGroup({
      ipoSize: new FormControl('',[Validators.required, Validators.minLength(1)]),
      demandQuantity: new FormControl('',[Validators.required, Validators.minLength(1)]),
      numberOfLotsToBought: new FormControl('',[Validators.required, Validators.minLength(1)])
    })
  }

  onSubmit() {
    this.submitted = true;
    if (!this.proportionalDistributionForm.invalid) {
      this.demandFloor =this.proportionalDistributionForm.controls["demandQuantity"].value/this.proportionalDistributionForm.controls["ipoSize"].value
      this.supplyRate = 1 / this.demandFloor
      this.result =this.proportionalDistributionForm.controls["numberOfLotsToBought"].value*this.supplyRate
      this.result=this.result.toFixed(5);
    }else{
      return;
    }
  }

}
