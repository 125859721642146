import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-arbitrage',
  templateUrl: './arbitrage.component.html',
  styleUrls: ['./arbitrage.component.scss']
})
export class ArbitrageComponent implements OnInit {
  faArrUp=faArrowUp;
  toYear=(new Date()).getFullYear();
  arbitrajForm!: FormGroup;
  submitted = false;
  result:any;
  constructor() { }

  ngOnInit(): void {
    this.arbitrajForm = this.createFormGroup()
  }
  createFormGroup(): FormGroup {
    return new FormGroup({
      firstValue: new FormControl('',[Validators.required, Validators.minLength(1)]),
      secondValue: new FormControl('',[Validators.required, Validators.minLength(1)])
    })
  }

  
  onSubmit() {
    this.submitted = true;
    if (!this.arbitrajForm.invalid) {
      this.result=this.arbitrajForm.controls["firstValue"].value*this.arbitrajForm.controls["secondValue"].value;
      this.result=this.result.toFixed(5);
    }else{
      return;
    }
  }

}
