import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnDestroy, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { Info } from 'src/app/core/models/info';
import { Page } from 'src/app/core/models/page';
import { SortData } from 'src/app/core/models/sort-data';
import { AnalysisService } from 'src/app/core/services/analysis.service';
import { InfoService } from 'src/app/core/services/info.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-summary-info',
  encapsulation:ViewEncapsulation.None,
  templateUrl: './summary-info.component.html',
  styleUrls: ['./summary-info.component.scss']
})
export class SummaryInfoComponent implements OnInit,OnDestroy {
  domainURL=environment.domain+"/"+(this.localId+"").substring(0,2)+"/";
  infoList:Info[];
  isBrowser: boolean=false;
  destroy$: Subject<boolean> =new Subject<boolean>();

  constructor(
    @Inject(LOCALE_ID) private localId: Object,
    @Inject(PLATFORM_ID) private platformId: Object,
    public router: Router,
    private route: ActivatedRoute,
    public infoService: InfoService,
    public analysisService: AnalysisService
  ) { }

  ngOnInit(): void {
    this.isBrowser=isPlatformBrowser(this.platformId);
    if (this.isBrowser||isPlatformServer(this.platformId)) {
      this.getInfo();
    }
  }

  ngOnDestroy(): void{
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getInfo() {
    let sortData=new SortData<Info>();
    let info = new Info();
    info.country="tr";
    info.lang="tr";
    sortData.filter=info;
    sortData.sorts=[{"dir": "desc", "prop": "createDate"}];
    let pageInfo = new Page();
    pageInfo.pageNumber = 0;
    pageInfo.size = 5;
    this.infoService.getPageAbleList(pageInfo,sortData).pipe(takeUntil(this.destroy$)).subscribe(pagedData => {
      this.infoList = pagedData.data;
    },
    error => {
      console.log("error: ",error);
    });

  }

  public trackByFnInfo(index, item) {
    if (!item) return null;
    return item.id;
  }

}
