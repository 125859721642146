<div id="wrapper" class="wrapper clearfix">
  <section id="tabs" class="project-tab tab-edit">
    <div class="container pairblank">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-8 mb-30-xs mb-30-sm">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="container pairblank">
                <div class="row mb-3" style="padding: 15px;">
                  <form [formGroup]="bondCalculateForm" (ngSubmit)="onSubmit()" novalidate>
                    <h1><a [routerLink]="['/calculate/tahvil-hesaplama']" title="Tahvil Hesaplama">Tahvil Hesaplama</a></h1>
                    <p>Yıllık Kupon Ödemesi:</p>
                    <input type="number" formControlName="annualCouponPayment" class="form-control mt-3 mb-3"
                           placeholder="Yıllık Kupon Ödemesi Giriniz" aria-describedby="basic-addon2">
                    <p>Tahvil Fiyatı:</p>
                    <input type="number" formControlName="bondPrice" class="form-control mt-3 mb-3"
                           placeholder="Tahvil Fiyatı Giriniz" aria-describedby="basic-addon2">
                    <label class='mt-3 mb-3' *ngIf="result" style="display:block">Sonuç: %{{result}}</label>
                    <button class="btn btn-primary mt-3 mb-3" i18n="@@buttonCalculator">Hesapla</button>
                  </form>
                  <div class="widget market-pricing market-list card mb-3" style="position: relative; padding:10px;">
                    <div class="row">
                      <div class="col-4" style="width:auto;height:auto;">
                        <picture>
                          <source media="(max-width: 90px)" srcset="tahvil.webp" />
                          <source media="(min-width: 90px)" srcset="tahvil.webp" />
                          <img loading="lazy" width="90px" height="60px" src="tahvil.webp" alt="Tahvil"
                               title="Tahvil">
                        </picture>
                      </div>
                      <div class="col-8">
                        <h3 class="h5"><strong>Tahvil Nedir?</strong></h3>Devlet veya kurumsal işletmelerin finansman ihtiyacını karşılamak için vadesi bir yıldan fazla olan borçlanma senetlerine tahvil denmektedir.
                      </div>
                      <div class="w-100"></div>
                      <br>
                      <p>Tahvil yatırımlarında, yatırımcılar tahvil ihracı aşamasında belirtilmiş sabit ya da değişken faiz oranlarından vade sonunda getiri elde ederler.</p>
                      <p>Devlet tahvilleri<a href='/tr/info/temerrut-nedir' title='Temerrüt Nedir' alt='Temerrüt Nedir' class='href'> temerrüt</a>(belirlenmiş olan yükümlülüklerin, taraflardan bir tanesi tarafından yerine getirilmemesi) riski taşımazken, özel şirket tahvillerinde ise bu risk bulunmaktadır.</p>
                      <p>Tahvil ihracını gerçekleştiren ülkenin para biriminden farklı bir para birimi üzerine yapılan tahviller ise 'Eurobond' ismini almaktadır. Bu tahvillerin ihracı bir banka ya da konsorsiyum ile yapılabilmektedir.</p>
                      <p>Özel şirketlerin çıkarmış oldukları menkul kıymetlere yatırım yapılacağında vade ve faiz riskinin devlet tahvillerine nazaran daha yüksek olmasından ötürü tahvili alınacak şirketin, sağlam bir mali analiz sürecinden geçirilmesi gerekmektedir.

                        Tahvillerin ihraç koşulları ihraç edildiği ülke ve pazarların özel koşullarına ve düzenlemelerine tabi olmaktadır. Bu doğrultuda tahvillerin türleri, nasıl ihraç edileceği,<a href='/tr/info/halka-arz-kazandirir-mi' title='Halka Arz Kazandırır Mı?' alt='Halka Arz Kazandırır Mı?' class='href'><strong>halka arz</strong></a> edilen tahvillerde arz koşulları, borsalara kotasyon koşul ve yöntemleri, teminatlarının neler olacağı, geri çağrılma koşulları, <a href='/tr/info/faiz-nedir' title='Faiz Nedir' alt='Faiz Nedir' class='href'><strong>faiz</strong></a> ve diğer menfaatlerin sağlanma şekilleri, tahvil sahiplerinin hakları ve ihraç eden şirketin yükümlülükleri ticaret hukuku ve sermaye hukuku tarafından düzenlenmiştir.</p>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4">
          <app-draft-calculate></app-draft-calculate>
          <app-draft-dividend [toYear]="toYear"></app-draft-dividend>
          <app-summary-info></app-summary-info>
          <app-summary-analysis [imageStatus]="true" [count]="5"></app-summary-analysis>
        </div>
      </div>
    </div>
  </section>
  <div id="back-to-top" class="backtop">
    <fa-icon [icon]="faArrUp"></fa-icon>
  </div>
