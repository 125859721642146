<div class="container pairblank">
    <div class="row mb-3" style="padding: 15px;">
  <form [formGroup]="ruchanForm" (ngSubmit)="onSubmit()" novalidate>
    <p>Hisse Adedini Giriniz:</p>
    <input type="number" formControlName="stockCountValue" class="form-control mt-3 mb-3"
    placeholder="Sahip Olduğunuz Hisse Adedi" aria-describedby="basic-addon2">
    <p>Rüçhan Hakkı Değerini Giriniz:</p>
    <input type="number" formControlName="ruchanValue" class="form-control mt-3 mb-3"
    placeholder="Rüçhan Değeri" aria-describedby="basic-addon2">
    <p>Bedelli Sermaye Oranını Giriniz:</p>
    <input type="number" formControlName="bedelliSermayeValue" class="form-control mt-3 mb-3"
    placeholder="Bedelli Oranı" aria-describedby="basic-addon2">
    <label class='mt-3 mb-3' *ngIf="result" style="display:block">Sonuç: {{ result| currency:'TRY'}}</label>
    <button class="btn btn-primary mt-3 mb-3" i18n="@@buttonCalculator">Hesapla</button>
  </form>
  </div>
  </div>