import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-even-distribution',
  templateUrl:'./even-distribution.component.html',
  styleUrls: ['./even-distribution.component.scss']
})
export class EvenDistributionComponent implements OnInit {
  evenDistributionForm: FormGroup;
  result: any;
  submitted: boolean;
  faArrUp=faArrowUp;
  toYear=(new Date()).getFullYear();

  constructor() { }

  ngOnInit(): void {
    this.evenDistributionForm = this.createFormGroup()
  }

  createFormGroup(): FormGroup {
    return new FormGroup({
      numberOfPeople: new FormControl('',[Validators.required, Validators.minLength(1)]),
      ipoSize: new FormControl('',[Validators.required, Validators.minLength(1)])
    })
  }

  onSubmit() {
    this.submitted = true;
    if (!this.evenDistributionForm.invalid) {
      this.result=this.evenDistributionForm.controls["ipoSize"].value/this.evenDistributionForm.controls["numberOfPeople"].value
      this.result=this.result.toFixed(5);
    }else{
      return;
    }
  }

}
