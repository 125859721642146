import { AfterViewInit, Component, Inject, LOCALE_ID, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { faFacebook,faTwitter,faLinkedin,faInstagram,faWikipediaW,faYoutube,faWeebly } from '@fortawesome/free-brands-svg-icons';
@Component({
  selector: 'app-footer',
  encapsulation:ViewEncapsulation.None,
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit ,AfterViewInit{
  domainURL=environment.domain+"/"+(this.localId+"").substring(0,2)+"/";
  facebookUrl:string;
  instagramUrl:string;
  twitterUrl:string;
  linkedinUrl:string;
  youtubeUrl:string;
  webwikiUrl:string;
  faFacebook=faFacebook
  faTwitter=faTwitter
  faInstagram=faInstagram
  faLinkedin=faLinkedin
  faWikipedia=faWikipediaW
  faYoutube=faYoutube
  faWeebly=faWeebly
  constructor(
    @Inject(LOCALE_ID) private localId: Object,
    public ngbModal: NgbModal,
  ) { }

  ngOnInit(): void {
    this.facebookUrl=environment.facebookUrl;
    this.instagramUrl=environment.instagramUrl;
    this.twitterUrl=environment.twitterUrl;
    this.linkedinUrl=environment.linkedinUrl;
    this.youtubeUrl=environment.youtubeUrl;
    this.webwikiUrl=environment.webwikiUrl;
  }
  ngAfterViewInit(): void {

  }
  
  openPopup(template,size='lg') {
    this.ngbModal.open(template, { size: size });
  }
}
