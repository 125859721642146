import { isPlatformServer } from "@angular/common";
import { from } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { tap} from 'rxjs/operators';

export class WrapperService {

    static getData(transferHttp,platformId,url, options, callback: () => Observable<any>): Observable<any> {
        const optionsString = options ? JSON.stringify(options) : '';
        let key = `${url + optionsString}`;
        try {
          if (isPlatformServer(platformId)) {            
            return  callback()
            .pipe(tap((data) => {
              this.setCache(transferHttp,key, data);
            }))
          }
          return this.resolveData(transferHttp,key)
        } catch (e) {          
          return callback()
            .pipe(tap((data) => {              
              this.setCache(transferHttp,key, data);
            }))
        }
      }
      static resolveData(transferHttp,key) {
        let resultData: any;
        if (this.hasKey(transferHttp,key)) {          
          resultData = this.getFromCache(transferHttp,key);
        } else {
          throw new Error()
        }
        return from(Promise.resolve(resultData))
      }
      static setCache(transferHttp,key, value) {
        transferHttp.set(key, value)
      }
      static getFromCache(transferHttp,key) {
        return transferHttp.get(key, null) // null set as default value
      }
      static hasKey(transferHttp,key) {
        return transferHttp.hasKey(key)
      }
}
