<app-web-push></app-web-push>
<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler"
            aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <a [routerLink]="['/']" class="navbar-brand">
            <img src="{{domainURL}}logo.webp" alt="Halka Arz Takvimi ve Hisseler" title="Halka Arz Takvimi" width="200" height="40">
        </a>
        <div class="flex-grow-1 h-right-col ">
            <div class="collapse navbar-collapse justify-content-end mt-4 mt-lg-0 " id="navbarToggler">
                <div class="search-col mb-3 mb-lg-0">
                    <div class="input-group rounded">
                        <input type="search" class="form-control rounded" placeholder="Search" aria-label="Search"
                            aria-describedby="search-addon" id="search" name="search" #search
                            (keyup.enter)="keytabSearchContent($event)" (keyup)="sendData($event)">
                        <span id="search-addon" class="input-group-text border-0" type="button" (click)="clickme(search.value)">
                            <fa-icon [icon]="faSearch" aria-hidden="true"
                            class="header-icons"style="font-size: 17px;"></fa-icon>
                        </span>
                    </div>
                </div>
                <ul class="navbar-nav flex-grow-1 justify-content-end navbar-col">
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/ipo-calendar']" i18n="@@ipo-calendar">Halka Arz Takvimi</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/dividend']" i18n="@@dividend">Temettü</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/info']" i18n="@@mustInfo">Gerekli Bilgiler</a>
                    </li>
                   <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/stock-recommendation']" i18n="@@Suggestions">Hisse Önerileri</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/crypto']" i18n="@@coins">Kriptolar</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/analysis']" i18n="@@analysis">Analiz</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>
<ng-template #templateContact let-modal>
    <div class="modal-dialog modal-dialog-scrollable" style="max-width: 100%!important;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" i18n="@@contactTitle">İletişim</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="modal.dismiss('Cross click')"></button>
            </div>
            <div class="modal-body">
                <app-contacts></app-contacts>
            </div>
        </div>
    </div>
</ng-template>
