export class Utils {
  public readonly DEFAULT_LANGUAGE_CODE = 'tr';
  public static months = {"Ocak" : 0, "Şubat" : 1, "Mart" : 2,"Nisan" : 3,"Mayıs" : 4,"Haziran" : 5,"Temmuz" : 6,"Ağustos" : 7,"Eylül" : 8,"Ekim" : 9,"Kasım" : 10,"Aralık" : 11};
  public static monthStrings = {"Ocak" : 'ocak', "Şubat" : 'subat', "Mart" : 'mart',"Nisan" : 'nisan',"Mayıs" : 'mayis',"Haziran" : 'haziran',"Temmuz" :'temmuz',"Ağustos" : 'agustos',"Eylül" : 'eylul',"Ekim" : 'ekim',"Kasım" : 'kasim',"Aralık" : 'aralik'};
  public static monthsEn = {"January" : 0, "February" : 1, "March" : 2, "April" : 3, "May" : 4, "June" : 5, "July" : 6, "August" : 7, "September" : 8, "October" : 9, "November" : 10, "December" : 11};
  public static monthStringsEn = {"January" : 'january', "February" : 'february', "March" : 'march', "April" : 'april', "May" : 'may', "June" : 'june', "July" : 'july', "August" : 'august', "September" : 'september', "October" : 'october', "November" : 'november', "December" : 'december'};
  public static createKeywords = (name) => {
    let arrName = [];
    let finalSentence = name.replace('  ', ' ').split(' ');
    finalSentence = Utils.removeNullUndefinedEmptyFromArray(finalSentence);
    finalSentence.forEach((element) => {
      arrName.push(
        ...Utils.createWordsKeywords(element.trim().toLocaleLowerCase())
      );
    });
    arrName.push(...Utils.createWordsKeywords(name.toLocaleLowerCase()));
    return arrName;
  };
  public static removeNullUndefinedEmptyFromArray = (arrayToClean) => {
    const cleanedArray = [];
    arrayToClean.forEach((val) => {
      if (
        val !== null &&
        typeof val !== 'undefined' &&
        ('' + val).trim() !== ''
      ) {
        cleanedArray.push(val);
      }
    });

    return cleanedArray;
  };

  public static createWordsKeywords = (name) => {
    const arrName = [];
    let curName = '';
    name.split('').forEach((letter) => {
      curName += letter;
      arrName.push(curName);
    });
    return arrName;
  };
  static DEFAULT_LANGUAGE_CODE: "tr" | "en";

  public static getMKeys(enumname: any) {
    let optionNames: Array<any> = [];
    for (let enumValue in enumname) {
      let optionNameLength = optionNames.length;
      if (optionNameLength === 0) {
        optionNames.push([enumValue, enumname[enumValue]]);
      } else {
        if (optionNames[optionNameLength - 1][1] !== enumValue) {
          optionNames.push([enumValue, enumname[enumValue]]);
        }
      }
    }
    return optionNames;
  }
  public static getUrlConcatIdAndText(text: string) {
    text=text.replace(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g,'').trim().replace(/[ ,]+/g, "-").toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim();
  
    return text;
  }

  public static  getUrlTrConvertEn(s) {
    return s.replace(/Ğ/gim, "g")
		.replace(/Ü/gim, "u")
		.replace(/Ş/gim, "s")
		.replace(/I/gim, "i")
		.replace(/İ/gim, "i")
		.replace(/Ö/gim, "o")
		.replace(/Ç/gim, "c")
		.replace(/ğ/gim, "g")
		.replace(/ü/gim, "u")
		.replace(/ş/gim, "s")
		.replace(/ı/gim, "i")
		.replace(/ö/gim, "o")
		.replace(/ç/gim, "c");
  }
  public static getLang(localId){
    if((localId+"").substring(0,2)!="en"){
      localId=(localId+"").substring(0,2);
    }else{
      localId="";
    }
    return localId;
  }

  public static checkMonthParam(monthParam:string,lang:string) {
    if(lang=='tr' && ['ocak', 'subat', 'mart', 'nisan', 'mayis','haziran','temmuz','agustos','eylul','ekim','kasim','aralik'].indexOf(monthParam)>-1){
      return "/"+monthParam
    }else if(lang=='en' && ['january', 'february', 'march', 'april', 'may','june','july','august','september','october','november','december'].indexOf(monthParam)>-1){
      return "/"+monthParam
    }else{
      return "";
    }
  }
  public static checkMonthParamString(monthParam:string,lang:string) {
   return lang=='en'?this.checkMonthParamStringEn(monthParam):this.checkMonthParamStringTr(monthParam);
  }
  public static checkMonthParamStringTr(monthParam:string) {
    let month;
    switch(monthParam){
      case "ocak" : month = "Ocak"; break;
      case "subat" : month = "Şubat"; break;
      case "mart" : month = "Mart"; break;
      case "nisan" : month = "Nisan"; break;
      case "mayis" : month = "Mayıs"; break;
      case "haziran" : month = "Haziran"; break;
      case "temmuz" : month = "Temmuz"; break;
      case "agustos" : month = "Ağustos"; break; 
      case "eylul" : month = "Eylül"; break;
      case "ekim" : month = "Ekim"; break;
      case "kasim" : month = "Kasım"; break;
      case "aralik" : month = "Aralık"; break;
      default : month ;
    }
    return month?month +" ":"";
  }
  public static checkMonthParamStringEn(monthParam:string) {
    let month;
    switch(monthParam){
      case "january" : month = "January"; break;
      case "february" : month = "February"; break;
      case "march" : month = "March"; break;
      case "april" : month = "April"; break;
      case "may" : month = "May"; break;
      case "june" : month = "June"; break;
      case "july" : month = "July"; break;
      case "august" : month = "August"; break; 
      case "september" : month = "September"; break;
      case "october" : month = "October"; break;
      case "november" : month = "November"; break;
      case "december" : month = "December"; break;
      default : month ;
    }
    return month?month +" ":"";
  }
  public static checkMonthParamNumber(monthParam:string) {
    let month;
    switch(monthParam){
      case "ocak" || "january" : month = 0; break;
      case "subat" || "february" : month = 1; break;
      case "mart" || "march" : month = 2; break;
      case "nisan" || "april" : month = 3; break;
      case "mayis" || "may" : month = 4; break;
      case "haziran" || "june" : month = 5; break;
      case "temmuz" || "july" : month = 6; break;
      case "agustos" || "august" : month = 7; break; 
      case "eylul" || "september" : month = 8; break;
      case "ekim" || "october" : month = 9; break;
      case "kasim" || "november" : month = 10; break;
      case "aralik" || "december" : month = 11; break;
      default : month ;
    }
    return month;
  }

  public static isValidYear(year: number): boolean {
    const currentYear = new Date().getFullYear();
    const maxAllowedYear = currentYear - 10;
    return year >= maxAllowedYear && year <= currentYear;
  }
  
  public static getValidYearOrDefault(year: number): number {
    const currentYear = new Date().getFullYear();
    const maxAllowedYear = currentYear - 10;
    if (year >= maxAllowedYear && year <= currentYear) {
      return year;
    } else {
      return currentYear;
    }
  }
  public static getValidYearOrDefaultString(yearString: string): number {
    try {
      const currentYear = new Date().getFullYear();
      const year = parseInt(yearString, 10);
      if (isNaN(year)) {
        return currentYear;
      }
      const maxAllowedYear = currentYear - 10;
      if (year >= maxAllowedYear && year <= currentYear) {
        return year; 
      } else {
        return currentYear;
      }
    } catch (error) {
      return new Date().getFullYear();
    }
  }
  
  public static getValidMonthOrCurrent(monthName: string, langCode: 'tr' | 'en'): string | null {
    if (!monthName || !langCode) {
      return null;
    }
    const months = {
      tr: ['ocak', 'subat', 'mart', 'nisan', 'mayis', 'haziran', 'temmuz', 'agustos', 'eylul', 'ekim', 'kasim', 'aralik'],
      en: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
    };
    try {
      
      const month = monthName.toLowerCase();
      const validMonths = months[langCode];
  
      if (validMonths.includes(month)) {
        return month;
      } else {
        const currentMonthIndex = new Date().getMonth();
        return validMonths[currentMonthIndex];
      }
    } catch (error) {
      const currentMonthIndex = new Date().getMonth();
      return months[langCode][currentMonthIndex];
    }
  }
  public static getLanguageCode(localId: any): 'tr' | 'en' {
    try {
      const localIdString = String(localId);
      if (!localIdString) {
        return this.DEFAULT_LANGUAGE_CODE;
      }
  
      const langCode = localIdString.substring(0, 2);
      return (langCode === 'tr' || langCode === 'en') ? langCode : this.DEFAULT_LANGUAGE_CODE;
    } catch (error) {
      return this.DEFAULT_LANGUAGE_CODE;
    }
  }
  
  
}
