<div class="widget widget-recent-posts">
    <div class="widget--title">
        <a [routerLink]="['/analysis']" title="Halka Arz ve Hisse Analizleri"><h2 class="info-detail-title">Analizler</h2></a>
    </div>
    <div class="entry" *ngFor="let analysis of analysisList; index as i; trackBy: trackByFnA">
        <a [routerLink]="['/analysis',analysis?.url]" title="{{analysis?.title}}">
            <div class="card mb-2">
                <div class="row g-0">
                    <div class="col-4  p-2 " >
                        <ng-container *ngIf="analysis?.imgUrl==null;  then iAmDone; else iAmNotDone">
                        </ng-container>
                        <ng-template #iAmDone>
                            <div class="col-4" style="width:auto;height:auto;">
                                <picture>
                                    <source media="(max-width: 100px)" srcset="{{domainURL}}halka-arz-detay-{{(i+5)%6}}.webp" />
                                    <source media="(min-width: 100px)" srcset="{{domainURL}}halka-arz-detay-{{(i+5)%6}}.webp" />
                                    <img loading="lazy" width="100px" height="80px" src="{{domainURL}}halka-arz-detay-{{(i+5)%6}}.webp" alt="{{analysis?.title}} Analiz" title="{{analysis?.title}}">
                                </picture>
                            </div>
                          </ng-template>
                          <ng-template #iAmNotDone>
                            <div class="col-4" style="width:auto;height:auto;">
                                <picture>
                                    <source media="(max-width: 100px)" srcset="{{domainURL}}{{analysis?.imgUrl}}" />
                                    <source media="(min-width: 100px)" srcset="{{domainURL}}{{analysis?.imgUrl}}" />
                                    <img loading="lazy" width="100px" height="80px" src="{{domainURL}}{{analysis?.imgUrl}}" alt="{{analysis?.title}} Analiz" title="{{analysis?.title}}">
                                </picture>
                            </div>
                          </ng-template>
                        <div class="entry--overlay"></div>
                    </div>
                    <div [ngClass]="imageStatus? 'col-8':''">
                        <div class="card-body ">
                            <div class="h5 card-title" style="font-size: 16px; color:black;">
                                <p>{{analysis?.title}}</p></div>
                            <div *ngIf="imageStatus" class="entry--date" style="font-size: 14px;">{{analysis.createDate
                                | date:'EEEE, MMMM d, y'}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    </div>
</div>
