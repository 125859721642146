import { DatePipe } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaSeoService {
  constructor(
    @Inject(LOCALE_ID) private localId: Object,
    public datepipe: DatePipe,
    private metaService:Meta,
    private title: Title
  ) { }
  setMetaAndTitle(title,description,canonicalURL,domainAndLangURL,noIndex,imageUrl=domainAndLangURL+"/logo.webp") {
    this.title.setTitle(title);
    this.metaService.updateTag({ httpEquiv: 'content-language', content: this.localId+"" },"http-equiv='content-language'");
    this.metaService.updateTag({ name: 'title', content: title },"name='title'");
    this.metaService.updateTag({ name: 'description', content: description },"name='description'");
    this.metaService.updateTag({ property: 'og:title', content: title},"property='og:title'");
    this.metaService.updateTag({ property: 'og:description', content: description },"property='og:description'");
    this.metaService.updateTag({ property: 'og:url', content:canonicalURL},"property='og:url'");
    this.metaService.updateTag({ property: 'og:image', content: imageUrl},"property='og:image'");
    this.metaService.updateTag({ name: 'twitter:title', content: title},"name='twitter:title'");
    this.metaService.updateTag({ name: 'twitter:description', content: description},"name='twitter:description'");
    this.metaService.updateTag({ name: 'twitter:image', content:imageUrl},"name='twitter:image'");
    this.metaService.updateTag({ name: 'twitter:url', content:canonicalURL},"name='twitter:url'");
    if(noIndex=="noindex"){
      this.metaService.updateTag({ name: 'robots', content: noIndex});
    }else{
      this.metaService.updateTag({ name: 'robots', content: 'index, follow' });
    }
    this.setMetaAndTitleDefault();
  }
  setMetaAndTitleAndImgAndArticle(title,description,canonicalURL,domainAndLangURL,noIndex,imgUrl,article) {
    this.setMetaAndTitle(title,description,canonicalURL,domainAndLangURL,noIndex);
    this.metaService.updateTag({ name: 'twitter:image', content:domainAndLangURL+"/"+imgUrl},"name='twitter:image'");
    this.metaService.updateTag({ property: 'og:image', content: domainAndLangURL+"/"+imgUrl},"property='og:image'");
    this.metaService.updateTag({ property: 'og:type', content: article},"property='og:type'");
  }
  setMetaAndTitleDefault() {
    this.metaService.addTags([
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'abstract', content: 'Halka Arz Info' },
      { name: 'author', content: 'Halka Arz Info, info@halkaarz.info' },
      { name: 'copyright', content: 'all rights reserved' },
      { name: 'revisit-after', content: '7 days' },
      //{ name: 'date', content:(this.datepipe.transform(new Date(), 'yyyy-MM-dd')), scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' },
      { property: 'og:site_name', content: 'Halka Arz Info'},
      { property: 'og:type', content: "article" },
      { property: 'og:image:height', content: "500"},
      { property: 'og:image:width', content: "500"},
      { property: 'og:app_id', content: "halkaarz_id"},
      { property: 'fb:app_id', content: "halkaarz_id"},
   //   { property: 'article:published_time', content:(this.datepipe.transform(new Date(), 'yyyy-MM-dd')), scheme: 'YYYY-MM-DD' },

      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:creator', content:"@HalkaArzInfo"},
      { name: 'twitter:site', content:"Halka Arz Info"},
    ]);
  }
}
