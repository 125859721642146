import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import { Page } from '../models/page';
import { PagedData } from '../models/paged-data';
import { SortData } from '../models/sort-data';
import { WrapperService } from '../utils/wrapper-service';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  pagedDataAny: any;
  urlSuffixPath="/user";
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private transferHttp: TransferState,
    private http: HttpClient
){
}

  getAutoCompleteList(term: string = null): Observable<User[]> {
    if (term) {
        let response=this.http.get<any>(`${environment.serviceApiUrl}${this.urlSuffixPath}/list_autocomp?term=${term}`).pipe(map(rsp => rsp))
        return response;
    } else {
        return of([]);
    }
  }

  getList(): any {
    return this.http.get<User[]>(`${environment.serviceApiUrl}${this.urlSuffixPath}/list`);
  }

  getPageAbleList(page: Page, sortData: SortData<User>): Observable<PagedData<User>> {
    let postUrl = `${environment.serviceApiUrl}${this.urlSuffixPath}` + "/pageable-list?page=" + (page.pageNumber + 1) + "&pageSize=" + page.size;
    return WrapperService.getData(this.transferHttp,this.platformId,postUrl, sortData, () => {
    return this.http.post(postUrl, sortData);
  })
}

  getByCode(code: string) {
    let postCode =`${environment.serviceApiUrl}${this.urlSuffixPath}/getByCode/` + code;
    return WrapperService.getData(this.transferHttp,this.platformId,postCode, null, () => {
      return this.http.get<any>(postCode);
      })
  }
  getByUrl(url: string) : Observable<User>{
    let postUrl = `${environment.serviceApiUrl}${this.urlSuffixPath}/get?url=${url}`;
    return WrapperService.getData(this.transferHttp,this.platformId,postUrl, null, () => {
      return this.http.get<any>(postUrl);
      })
  }
  getById(id: string) {
    let postId=`${environment.serviceApiUrl}${this.urlSuffixPath}/getById/` + id;
    return WrapperService.getData(this.transferHttp,this.platformId,postId, null, () => {
      return this.http.get<any>(postId);
      })
  }
  getByFingerPrintidId(id: string) {
    let postId=`${environment.serviceApiUrl}${this.urlSuffixPath}/get-finger-print-id?id=${id}`;
    return WrapperService.getData(this.transferHttp,this.platformId,postId, null, () => {
      return this.http.get<any>(postId);
      })
  }

  save(user: User):Observable<Object> {
    return this.http.post(`${environment.serviceApiUrl}${this.urlSuffixPath}/save`, user);
  }

  update(user: User) {
    return this.http.put(`${environment.serviceApiUrl}${this.urlSuffixPath}/update`, user);
  }

  delete(id: number):Observable<Object> {
    return this.http.post(`${environment.serviceApiUrl}${this.urlSuffixPath}/delete`, id);
  }

  checkFingerPrint(fingerprintid) {
    return new Promise<any>((resolve, reject) => {
    this.getByFingerPrintidId(fingerprintid).pipe(take(1)).subscribe({next: pagedData => {
      if(pagedData){
        let user=pagedData as User;
        localStorage?.setItem('halkaarz_userinfo',JSON.stringify(user))
        resolve(user)
      }else{
        resolve(null);
      }
    },
    error: error => {
      console.log("error: ",error);
    }});
   });
  }
}
