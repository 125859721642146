import { Component, Inject, LOCALE_ID, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-calculate-in',
  encapsulation:ViewEncapsulation.None,
  templateUrl: './calculate-in.component.html',
  styleUrls: ['./calculate-in.component.scss']
})
export class CalculateInComponent implements OnInit {
  domainURL=environment.domain+"/"+(this.localId+"").substring(0,2)+"/";
  calculateForm!: FormGroup;
  categories:any;
  submitted = false;
  result:any;
  numberOfAnswers: number;
  constructor(
    @Inject(LOCALE_ID) private localId: Object,
    public translate: TranslateService,
    public router: Router,
    private route: ActivatedRoute
    ) { }

  ngOnInit() {
    this.calculateForm = this.createFormGroup()
    this.newObject();
    this.route.data.subscribe(data => {
      if(data.dividendCalculatorResolver=='/calculate'){
        this.calculateForm.controls["dividendType"].setValue(0);
      }else if(data.dividendCalculatorResolver=='/calculate/bedelli-sermaye-artirimi-hesaplama'){
        this.calculateForm.controls["dividendType"].setValue(1);
      }else if(data.dividendCalculatorResolver=='/calculate/bedelsiz-sermaye-artirimi-hesaplama'){
        this.calculateForm.controls["dividendType"].setValue(2);
      }else if(data.dividendCalculatorResolver=='/calculate/sermaye-azaltimi-hesaplama'){
        this.calculateForm.controls["dividendType"].setValue(3);
      }else{
        this.calculateForm.controls["dividendType"].setValue(0);
      }
    })
  }
 
  createFormGroup(): FormGroup {
    return new FormGroup({
      stockCount: new FormControl('',[Validators.required, Validators.minLength(1)]),
      dividendType: new FormControl('',[Validators.required]),
      dividendValue: new FormControl('',[Validators.required, Validators.minLength(1)])
    })
  }

  onSubmit() {
    this.submitted = true;
    if (!this.calculateForm.invalid) {
      let result
      if(this.calculateForm.controls["dividendType"].value==0){
      result=this.calculateForm.controls["stockCount"].value*this.calculateForm.controls["dividendValue"].value}
      else if(this.calculateForm.controls["dividendType"].value==1 || this.calculateForm.controls["dividendType"].value==2){
      result=this.calculateForm.controls["stockCount"].value-(this.calculateForm.controls["stockCount"].value*(100-(this.calculateForm.controls["dividendValue"].value))/100);}
      else if(this.calculateForm.controls["dividendType"].value==3){
      result=this.calculateForm.controls["stockCount"].value/((this.calculateForm.controls["dividendValue"].value/100));}
      this.result=result.toFixed(5);
    }else{
      return;
    }
  }
  
  newObject() {
    this.calculateForm?.reset();
    this.submitted=false;
    this.calculateForm.controls["dividendType"].setValue(0);
    this.result=null;
  }

 }
