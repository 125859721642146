import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';

export class ErrorMsg {
 
    public static getMsg(translate: TranslateService,error: HttpErrorResponse): string {
        if(error!=null&&error.status==200&&error.error!=null&&error.error.text!=null){
            return error.error.text;
        }else if(error.status==400&&error?.error?.message){
            return error?.error?.message;
        }else if(!(error==null||error==undefined||error.message==null||error.message==undefined)){           
            return  translate.instant("error.general");
        }
        return error as unknown as string;
      }

    
      public static handleError(router,error) {
        router.navigateByUrl('/404');
        return throwError(error);
      }
   



}
