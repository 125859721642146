<div class="card mb-3 ">
    <div class="widget--title">
        <a class="href" [routerLink]="['/calculate']" title="Hesaplamalar"><h2 i18n="@@calculate" class="info-detail-title">Hesaplamalar</h2></a>
    </div>
    <ul class="list-unstyled m-2">
        <li class="list-group-item-light"><a [routerLink]="['/calculate/arbitraj-hesaplama']" class="href" title="Arbitraj Hesaplama" >Arbitraj Hesaplama</a></li>
        <li class="list-group-item-light"><a [routerLink]="['/calculate/asit-test-orani-hesaplama']" class="href" title="Asit Test Oranı Hesaplama" >Asit Test Oranı Hesaplama</a></li>
        <li class="list-group-item-light"><a [routerLink]="['/calculate/bedelli-capital-increase-hesaplama']" class="href" title="Bedelli Sermaye Artırımı Hesaplama" >Bedelli Sermaye Artırımı Hesaplama</a></li>
        <li class="list-group-item-light"><a [routerLink]="['/calculate/bedelsiz-capital-increase-hesaplama']" class="href" title="Bedelsiz Sermaye Artırımı Hesaplama" >Bedelsiz Sermaye Artırımı Hesaplama</a></li>
        <li class="list-group-item-light"><a [routerLink]="['/calculate/cari-oran-hesaplama']" class="href" title="Cari Oran Hesaplama" >Cari Oran Hesaplama</a></li>
        <li class="list-group-item-light"><a [routerLink]="['/calculate/esit-dagitim-hesaplama']" class="href" title="Eşit Dağıtım Hesaplama" >Eşit Dağıtım Hesaplama</a></li>
        <li class="list-group-item-light"><a [routerLink]="['/calculate/kaldirac-orani-hesaplama']" class="href" title="Finansal Kaldiraç Oranı" >Finansal Kaldiraç Oranı</a></li>
        <li class="list-group-item-light"><a [routerLink]="['/calculate/nakit-orani-hesaplama']" class="href" title="Nakit Temettü Hesaplama" >Nakit Oranı Hesaplama</a></li>
        <li class="list-group-item-light"><a [routerLink]="['/calculate/nakit-temettu-hesaplama']" class="href" title="Nakit Temettü Hesaplama" >Nakit Temettü Hesaplama</a></li>
        <li class="list-group-item-light"><a [routerLink]="['/calculate/oransal-dagitim-hesaplama']" class="href" title="Oransal Dağıtım Hesaplama" >Oransal Dağıtım Hesaplama</a></li>
        <li class="list-group-item-light"><a [routerLink]="['/calculate/sermaye-azaltimi-hesaplama']" class="href" title="Sermaye Azaltımı Hesaplama" >Sermaye Azaltımı Hesaplama</a></li>
        <li class="list-group-item-light"><a [routerLink]="['/calculate/tahvil-hesaplama']" class="href" title="Tahvil Hesaplama" >Tahvil Hesaplama</a></li>
        <li class="list-group-item-light"><a [routerLink]="['/calculate/fibonacci-hesaplama']" class="href" title="Fibonacci Hesaplama" >Fibonacci Hesaplama</a></li>


    </ul>
</div>
