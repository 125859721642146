<div id="wrapper" class="wrapper clearfix">
  <section id="tabs" class="project-tab tab-edit">
    <div class="container pairblank">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-8 mb-30-xs mb-30-sm">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="container pairblank">
                <div class="row mb-3" style="padding: 15px;">
                  <form [formGroup]="cashRatioForm" (ngSubmit)="onSubmit()" novalidate>
                    <h1><a [routerLink]="['/calculate/nakit-orani-hesaplama']" title="Nakit Oranı Hesaplama">Nakit Oranı
                        Hesaplama</a></h1>
                    <p>Nakit ve Nakit Benzerleri :</p>
                    <input type="number" formControlName="cashEquivalents" class="form-control mt-3 mb-3"
                      placeholder="Nakit ve Nakit Benzerlerini Giriniz" aria-describedby="basic-addon2">
                    <p>Kısa Vadeli Yükümlülükler:</p>
                    <input type="number" formControlName="shortTermLiabilities" class="form-control mt-3 mb-3"
                      placeholder="Kısa Vadeli Yükümlülükleri Giriniz" aria-describedby="basic-addon2">
                    <label class='mt-3 mb-3' *ngIf="result" style="display:block">Sonuç: %{{result}}</label>
                    <button class="btn btn-primary mt-3 mb-3" i18n="@@buttonCalculator">Hesapla</button>
                  </form>
                  <div class="widget market-pricing market-list card mb-3" style="position: relative; padding:10px;">
                    <div class="row">
                      <div class="col-4" style="width:auto;height:auto;">
                        <picture>
                          <source media="(max-width: 90px)" srcset="nakit-orani.webp" />
                          <source media="(min-width: 90px)" srcset="nakit-orani.webp" />
                          <img loading="lazy" width="90px" height="60px" src="nakit-orani.webp" alt="Nakit Oranı"
                            title="Nakit Oranı">
                        </picture>
                      </div>
                      <div class="col-8">
                        <h3 class="h5"><strong>Nakit Oranı Nedir?</strong></h3> Cari oran ve asit orana nazaran, nakit
                        oranda stoklar, ticari alacaklar gibi ana dönen varlıklar hesapa katılmaz.
                      </div>
                      <div class="w-100"></div>
                      <br>
                      <p>Bu oran elde ki tüm nakit ile bir yıl içerisinde ödenecekler borçları karşılama oranıdır.
                        Şirket olası bir aksilikte alacaklarını tahsil edemezse borçlarının ne kadarını elinde ki nakit
                        ile ödeyebilmektedir.</p>
                         <ul>
                          <li>Nakit oran = 0,20 ise ideal oran olarak kabul edilmektedir.</li>
                          <li>Nakit Oran < 0,20 olduğu zaman; işletme para konusunda sorunlar yaşayabilecek olması olarak yorumlanmaktadır.</li>
                          <li>Nakit Oran >0,20 ise, işletmedeki paranın verimli bir biçimde kullanılmadığını olarak yorumlanmaktadır.</li>
                        </ul>
                        <p>Not: Oranlardaki ideal aralıklar ülkeden ülkeye, sektörden sektöre değişiklik gösterebilmektedir. Yatrımcıların bu değerleri kontrol ettikleri zamanlarda sektör ortalamalarını da göz önünde bulundurmaları daha sağlıklı sonuçlar elde etmelerine olanak sağlayacaktır.</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4">
          <app-draft-calculate></app-draft-calculate>
          <app-draft-dividend [toYear]="toYear"></app-draft-dividend>
          <app-summary-info></app-summary-info>
          <app-summary-analysis [imageStatus]="true" [count]="5"></app-summary-analysis>
        </div>
      </div>
    </div>
  </section>
  <div id="back-to-top" class="backtop">
    <fa-icon [icon]="faArrUp"></fa-icon>
  </div>