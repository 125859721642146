<div class="widget widget-recent-posts">
    <div class="widget--title">
        <a [routerLink]="['/info']" title="Borsa Hakkında Bilinmesi Gerekenler"><h2 class="info-detail-title">Gerekli Bilgiler</h2></a>
    </div>
    <div class="entry" *ngFor="let info of infoList; index as i; trackBy: trackByFnInfo">
        <div class="card mb-3" style="max-width: 540px;position:sticky ;">
            <a [routerLink]="['/info',info?.url]" title="{{info?.title}}">
                <div class="row g-0">
                    <div class="col-4  p-2 ">
                        <ng-container *ngIf="info?.imageUrl==null;  then iAmDone; else iAmNotDone">
                        </ng-container>
                        <ng-template #iAmDone>
                            <div class="col-4" style="width:auto;height:auto;">
                                <picture>
                                    <source media="(max-width: 100px)" srcset="{{domainURL}}halka-arz-detay-{{(i+5)%6}}.webp" />
                                    <source media="(min-width: 100px)" srcset="{{domainURL}}halka-arz-detay-{{(i+5)%6}}.webp" />
                                    <img loading="lazy" width="100px" height="80px" src="{{domainURL}}halka-arz-detay-{{(i+5)%6}}.webp" alt="{{info?.title}} Gerekli Bilgiler" title="{{info?.title}}">
                                </picture>
                            </div>
                          </ng-template>
                          <ng-template #iAmNotDone>
                            <div class="col-4" style="width:auto;height:auto;">
                                <picture>
                                    <source media="(max-width: 100px)" srcset="{{domainURL}}{{info?.imageUrl}}" />
                                    <source media="(min-width: 100px)" srcset="{{domainURL}}{{info?.imageUrl}}" />
                                    <img loading="lazy" width="100px" height="80px" src="{{domainURL}}{{info?.imageUrl}}" alt="{{info?.title}} Gerekli Bilgiler" title="{{info?.title}}">
                                </picture>
                            </div>
                          </ng-template>
                        <div class="entry--overlay"></div>
                    </div>
                    <div class="col-8">
                        <div class="card-body">
                            <div class="h5card-title" >
                                <div class="h5 card-title" style="font-size: 16px; color:black;">
                                    <p>{{info?.title}}</p></div>
                                <div class="entry--date" style="font-size: 14px;">{{info.createDate
                                    | date:'EEEE, MMMM d, y'}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>
