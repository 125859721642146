import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactsComponent } from './contacts.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { httpTranslateLoader } from '../core/core.module';


@NgModule({
  declarations: [ContactsComponent],
  imports: [
    CommonModule,  
    FormsModule,
    ReactiveFormsModule,  
    TranslateModule.forChild({
      loader: {
      provide: TranslateLoader,
      useFactory: httpTranslateLoader,
      deps: [HttpClient]
    },
    isolate: false
    }),
  ],
  exports:[
    ContactsComponent
  ]
})
export class ContactsModule { }
