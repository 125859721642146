
    <form [formGroup]="calculateForm" (ngSubmit)="onSubmit()" novalidate>
        <br>
        <div>
            <input type="radio" class="btn-check" [value]="0"
              formControlName="dividendType" id="cshDvdnd" checked>
            <label class="btn btn-outline-secondary mx-1"  for="cshDvdnd">Nakit Temettü Hesaplama</label>
            <input type="radio" class="btn-check" [value]="1"
              formControlName="dividendType" id="fCapital">
            <label class="btn btn-outline-secondary mx-1" for="fCapital">Bedelli Sermaye Artırımı Hesaplama</label>
            <input type="radio" class="btn-check" [value]="2"
              formControlName="dividendType" id="pCapital">
            <label class="btn btn-outline-secondary mx-1" for="pCapital">Bedelsiz Sermaye Artırımı Hesaplama</label>
            <input type="radio" class="btn-check" [value]="3"
              formControlName="dividendType" id="rCapital">
            <label class="btn btn-outline-secondary mx-1" for="rCapital">Sermaye Azaltımı Hesaplama</label>
          </div> 
          <div class="col-12 pb-5 mx-auto" style="width: 102%;">
            <br>
            <label for="basic-url" class="form-label">Hisse Adedi :</label>
            <div class="input-group">
              <input type="number" formControlName="stockCount" class="form-control"
                placeholder="Sahip Olduğunuz Toplam Hisse Adedini Giriniz ..." aria-describedby="hisseAdedi">
            </div>
            <br>
            <div>
              <label for="basic-url" class="form-label"> 
                <ng-container *ngIf="calculateForm?.controls['dividendType']?.value==0">
                  Nakit Temettü :
                </ng-container>
                <ng-container *ngIf="calculateForm?.controls['dividendType']?.value==1">
                  Sermaye Artırımı Bedelli Oranı (%) :
                </ng-container>
                <ng-container *ngIf="calculateForm?.controls['dividendType']?.value==2">
                  Sermaye Artırımı Bedelsiz Oranı (%) :
                </ng-container>  
                <ng-container *ngIf="calculateForm?.controls['dividendType']?.value==3">
                  Sermaye Azaltımı Oranı (%) :
                </ng-container>            
              </label>
              <div class="input-group">
                <input type="number" formControlName="dividendValue" class="form-control"
                  [placeholder]="calculateForm?.controls['dividendType']?.value==0?
                  'Ortaklara Dağıtılacak Hisse Başı Net Tutar':
                  (calculateForm?.controls['dividendType']?.value==1?'Bedelli Sermaye Artırımı Yüzdesi':
                  (calculateForm?.controls['dividendType']?.value==2?'Bedelsiz Sermaye Artırımı Yüzdesi':'Sermaye Azaltımı Yüzdesi'))" 
                  aria-describedby="basic-addon2">
                <span class="input-group-addon" id="basic-addon2"></span>
              </div>
            </div>
            <br>
            <button class="btn btn-primary" i18n="@@buttonCalculator">Hesapla</button>
            <div class="pt-3">{{ result| currency:'TRY'}}</div>
            <br>
            <div >
              <ng-container *ngIf="calculateForm?.controls['dividendType']?.value==0">
                <h3>Temettü Nedir?</h3>
                  <p>Şirketlerin bilanço dönemleri sonunda hissedarların kârından pay alma hakkına denir. Kâr payının dağıtım şekli nakit veya hisse senedi olabilir ve şirketten şirkete göre değişkenlik gösterebilmektedir. Temettü ödemesinin ne kadar olacağı ise bir şirketin yönetim kurulu tarafından belirlenir.</p>
                  <h4><strong>Temettü Dağıtımı Sonrası Hisse Fiyatları Ne Olur?</strong></h4>
                  <p>Temettü dağıtımı sırasında, temettü veren şirketin sermayesinden temettü oranında para çıkar. Temettü dağıtımı sonrası hisse senedinde, temettü oranında düşüş yaşanır. Örneğin temettüden önce değeri 100 TL olan bir hisse olsun. Bu hissenin sahibi ve temettü dağıtacak olan şirket 5TL Temettü dağıtacaklarını açıklasın ve dağıtsın. Temettü dağıtılan hisse ertesi gün borsada 100-5 = 95TL den işlem görmeye başlayacaktır.</p>
                  <p style="display: inline;">Şirketlerin dağıtacakları Temettü tutarını hesaplamak için;</p>
                  <div><h5 class="h6" style="display: inline; color:chocolate"><strong>👉 'Nakit Temettü Hesaplayıcı'</strong> </h5> yı kullanabilirsiniz.</div>
                  <img src="{{domainURL}}temettu-hesaplama.webp" alt="Temettü Hesaplama" title="Temettü Hesaplama" hidden>
                </ng-container>
              <ng-container *ngIf="calculateForm?.controls['dividendType']?.value==1">
                <h2>Bedelli Sermaye Artırımı Nedir?</h2>
                <p>Bedelli sermaye artırımı, bir firmanın sermayesine karşılık gelen hisse senetlerini, genel kurul kararıyla mevcut sayısından fazla haline getirmesine denir.</p>
                <h3><strong>Bedelli Sermaye Artırımı Hisseyi Nasıl Etkiler?</strong></h3>
                <p>Hisse sahiplerinin merak ettiği konulardan biri de 'Bedelli Sermaye Artırımı Hisseyi Nasıl Etkiler?' dir. Hissenin geleceği belirsizleştiğinden ve 
                  hisse sahiplerinin bu belirsizlik sebebiyle hisselerini satması sebebiyle bedelli sermaye artırımı hisse sonrası hisse fiyatı kısa vadede düşüş yaşar.</p>
                <h4><strong>Bedelli Sermaye Artırımı SPK Onay Süresi Kaç Gün?</strong></h4>
                <p>Bununla ilgili spesifik bir gün aralığı bildirilmemişse de Spk onayları genelde başvuru tarihinden sonraki 30 gün içinde gerçekleşir.</p>
                <p style="display: inline;">Şirketlerin Bedelli Sermaye Artırımı Sonrası Hisse Fiyatını hesaplamak için;</p>
                <div><h5 class="h6" style="display: inline; color:chocolate"><strong>👉 'Bedelli Sermaye Artırımı Hesaplayıcı'</strong> </h5> yı kullanabilirsiniz.</div>
                <img src="{{domainURL}}bedelli-sermaye-artirimi-hesaplama.webp" alt="Bedelli Sermaye Artırımı Hesaplama" title="Bedelli Sermaye Artırımı Hesaplama" hidden>
              </ng-container>
              <ng-container *ngIf="calculateForm?.controls['dividendType']?.value==2">
                <h3>Bedelsiz Sermaye Artırımı Nedir?</h3>
                <p>Bedelsiz sermaye artırımı, bir firmanın sermayesine karşılık gelen ve şirketin var olan hisse senetlerini bedelsiz sermaye oranı dahilinde hissedarları arasında dağıtması işlemidir.</p>
                <h4><strong>Bedelsiz Sermaye Artırımı Hisseyi Nasıl Etkiler?</strong></h4>
                <p>Hisse sahiplerinin merak ettiği konulardan biri de 'Bedelsiz Sermaye Artırımı Hisseyi Nasıl Etkiler?'  dir. Hisse senetlerinin de aynı paranın piyasada bulunan mevcut miktarını tanımlayan 'likidite' 
                   kavramına benzer şekilde mevcut sayısı ile değeri arasında ters orantı vardır. Bedelli sermaye artırımı, hisselerin mevcut sayısı artmadan hissedarlar arasında paylaşılma işlemi olduğu için hisse fiyatı sabit kalabilir veya şirketin ve borsanın durumuna göre bir miktar artabilir.</p>
                <h4><strong>Bedelsiz Sermaye Artırımı&nbsp;</strong>SPK Onay Süresi Kaç Gün?</h4>
                <p>Bununla ilgili spesifik bir gün aralığı bildirilmemişse de Spk onayları genelde başvuru tarihinden sonraki 30 gün içinde gerçekleşir.</p>
                <p style="display: inline;">Şirketlerin Bedelsiz Sermaye Artırımı Sonrası Hisse Fiyatını hesaplamak için; </p>
                <div><h5 class="h6" style="display: inline; color:chocolate"><strong>👉 'Bedelsiz Sermaye Artırımı Hesaplayıcı'</strong> </h5> yı kullanabilirsiniz.</div>
                <img src="{{domainURL}}bedelsiz-sermaye-artirimi-hesaplama.webp" alt="Bedelsiz Sermaye Artırımı Hesaplama" title="Bedelsiz Sermaye Artırımı Hesaplama" hidden>
              </ng-container>  
              <ng-container *ngIf="calculateForm?.controls['dividendType']?.value==3">
                <h3>Sermaye Azaltımı Nedir?</h3>
                <p>Şirketlerin mevcut sermayesini azaltarak hisse değerini artırma işlemidir. Şirketler aynı anda hem sermaye azaltımı hem de sermaye artırımı yapabilirler</p>
                <h4><strong>Sermaye Azaltımı Neden Yapılır?</strong></h4>
                <p>Sermaye azaltımı, şirketin hisse değerini artırmak suretiyle şirketin zararını kapatmak veya sermayenin belli bir miktarını hissedarlarına iade etmesi amacıyla yapılabilmektedir.</p>
                <p>Sermaye Azaltımı Sonrası hissedarların şirket hisselerinde payı değişmezken hisse fiyatlarında sermaye azaltımı oranında bir miktar artış gözlemlenir..</p>
                <p style="display: inline;">Şirketlerin Sermaye Azaltımı Sonrası Hisse Fiyatını hesaplamak için; </p>
                <div><h5 class="h6" style="display: inline; color:chocolate"><strong>👉 ' Sermaye Azaltımı Hesaplayıcı'</strong> </h5> yı kullanabilirsiniz.</div>
                <img src="{{domainURL}}sermaye-azaltimi-hesaplama.webp" alt="Sermaye Azaltımı Hesaplama" title="Sermaye Azaltımı Hesaplama" hidden>
              </ng-container>   
              </div>
          </div>
        </form>