<div id="wrapper" class="wrapper clearfix">
  <section id="tabs" class="project-tab tab-edit">
    <div class="container pairblank">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-8 mb-30-xs mb-30-sm">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">

              <div class="container pairblank">
                <div class="row mb-3" style="padding: 15px;">
                  <form [formGroup]="acidTestRatioForm" (ngSubmit)="onSubmit()" novalidate>
                    <h1><a [routerLink]="['/calculate/asit-test-orani-hesaplama']" title="Asit Test Oranı Hesaplama" >Asit Test Oranı Hesaplama</a></h1>
                    <p>Dönen Varlıklar:</p>
                    <input type="number" formControlName="currentAssets" class="form-control mt-3 mb-3"
                      placeholder="Dönen Varlıklar Giriniz" aria-describedby="basic-addon2">
                    <p>Stoklar:</p>
                    <input type="number" formControlName="stocks" class="form-control mt-3 mb-3"
                      placeholder="Stokları Giriniz" aria-describedby="basic-addon2">
                    <p>Kısa Vadeli Yükümlülükler:</p>
                    <input type="number" formControlName="shortTermLiabilities" class="form-control mt-3 mb-3"
                      placeholder="Kısa Vadeli Yükümlülükleri Giriniz" aria-describedby="basic-addon2">
                    <label class='mt-3 mb-3' *ngIf="result" style="display:block">Sonuç: %{{result}}</label>
                    <button class="btn btn-primary mt-3 mb-3" i18n="@@buttonCalculator">Hesapla</button>
                  </form>
                  <div class="widget market-pricing market-list card mb-3" style="position: relative; padding:10px;">
                    <div class="row">
                      <div class="col-4" style="width:auto;height:auto;">
                        <picture>
                          <source media="(max-width: 90px)" srcset="asit-test-orani.webp" />
                          <source media="(min-width: 90px)" srcset="asit-test-orani.webp" />
                          <img loading="lazy" width="90px" height="60px" src="asit-test-orani.webp"
                            alt="Asit Test Oranı Nedir" title="Asit Test Oranı Nedir">
                        </picture>
                      </div>
                      <div class="col-8">
                        <h3 class="h5"><strong>Asit Test Oranı Nedir?</strong></h3> Dönen varlıkların stoklardan
                        çıkartılarak kısa
                        vadeli yükümlülüklere bölünmesi ile elde edilmektedir.
                      </div>
                      <div class="w-100"></div>
                      <br>
                      <p>Bir işletmeden hemen nakite dönebilecek varlıkların kısa vadeli yükümlülüklerini karşılama oranını göstermektedir. Asit test oranı sonucunda şirketin acil durumlarda kısa vadeli yükümlülüklerini karşılamaya yetecek kadar varlığının olup olmadığı belirlenmiş olur. Oldukça önemli bir likidite göstergesidir.</p>
                      <p> Bu oranın 1’in üzerinde olması ideal olarak kabul edilir ama 1’in altında olup alacak devir
                        hızı ve stok
                        devir hızları dönen varlıkları etkilemektedir. Bu noktada kullanıcılar sektör ortalamalarına
                        bakarak da fikir sahibi olabilirler. Ülkemiz şartlarında 0,8 gibi oranlarda kabul edilebilir bir değerdir.</p>
                        <p>Not: Asit test ismi eski zamanlarda altın madencileri tarafından kullanılan bir test yönteminden gelmektedir. Altın asite karşı dayanıklı bir maden olmasından dolayı madenciler buldukları madenin altın olup olmadığını asite koyarak test etmekteydiler.</p>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4">
          <app-draft-calculate></app-draft-calculate>
          <app-draft-dividend [toYear]="toYear"></app-draft-dividend>
          <app-summary-info></app-summary-info>
          <app-summary-analysis [imageStatus]="true" [count]="5"></app-summary-analysis>
        </div>
      </div>
    </div>
  </section>
  <div id="back-to-top" class="backtop">
    <fa-icon [icon]="faArrUp"></fa-icon>
  </div>
</div>