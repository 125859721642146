<div id="wrapper" class="wrapper clearfix">
  <section id="tabs" class="project-tab tab-edit">
    <div class="container pairblank">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-8 mb-30-xs mb-30-sm">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="container pairblank">
                <div class="row mb-3" style="padding: 15px;">
                  <form [formGroup]="currentRatioForm" (ngSubmit)="onSubmit()" novalidate>
                    <h1><a [routerLink]="['/calculate/cari-orani-hesaplama']" title="Cari Oranı Hesaplama">Cari Oranı
                      Hesaplama</a></h1>
                    <p>Dönen Varlıklar:</p>
                    <input type="number" formControlName="currentAssets" class="form-control mt-3 mb-3"
                      placeholder="Dönen Varlıkları Giriniz" aria-describedby="basic-addon2">
                    <p>Kısa Vadeli Yükümlülükler:</p>
                    <input type="number" formControlName="shortTermLiabilities" class="form-control mt-3 mb-3"
                      placeholder="Kısa Vadeli Yükümlülükleri Giriniz" aria-describedby="basic-addon2">
                    <label class='mt-3 mb-3' *ngIf="result" style="display:block">Sonuç: %{{result}}</label>
                    <button class="btn btn-primary mt-3 mb-3" i18n="@@buttonCalculator">Hesapla</button>
                  </form>
                  <div class="widget market-pricing market-list card mb-3" style="position: relative; padding:10px;">
                    <div class="row">
                      <div class="col-4" style="width:auto;height:auto;">
                        <picture>
                          <source media="(max-width: 90px)" srcset="cari-oran.webp" />
                          <source media="(min-width: 90px)" srcset="cari-oran.webp" />
                          <img loading="lazy" width="90px" height="60px" src="cari-oran.webp" alt="Cari Oran"
                            title="Cari Oran">
                        </picture>
                      </div>
                      <div class="col-8">
                        <h3 class="h5"><strong>Cari Oran Nedir?</strong></h3> Dönen varlıklar 1 yıl içerisinde nakde
                        dönüştürülebilir varlıkların toplamını göstermektedir.
                      </div>
                      <div class="w-100"></div>
                      <br>
                      <p>İşletmelerin kısa vadeli borçlarını ödeyebilme gücünü tespit etmekte kullanılmaktadır.  Bankaya kredi başvurusunda bulunacak olan firma için kredi neticesinde cari oran değeri önemli olmaktadır. Cari oranın yüksek olması, satıcı ve bankalar gibi vadeli borç verenler tarafından olumlu karşılanır bu oran firmanın borcunu rahatça geri ödeyebilecek olması olarak yorumlanmaktadır.</p>
                      <p> Cari oranın genel değeri 2 olarak kabul edilmektedir. Fakat bu oran, firmanın sektörüne, piyasa koşullarına, ülkenin ekonomik şartlarına, şirketin herhangi bir yatırımı olup olmadığına göre değişmektedir. Cari oranın 10 – 15 gibi aşırı yüksek olması ise firmanın sahip olduğu nakdi, yeterli verimlilikte kullanamadığına veya yanıltıcı bir bilançoya sahip olduğu şeklinde yorumlanmaktadır.  Cari oran yorumlanırken diğer oranlarla beraber yorumlanması yatırımcıların doğru kararlar almasında önem taşımaktadır.</p>
                  </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4">
          <app-draft-calculate></app-draft-calculate>
          <app-draft-dividend [toYear]="toYear"></app-draft-dividend>
          <app-summary-info></app-summary-info>
          <app-summary-analysis [imageStatus]="true" [count]="5"></app-summary-analysis>
        </div>
      </div>
    </div>
  </section>
  <div id="back-to-top" class="backtop">
    <fa-icon [icon]="faArrUp"></fa-icon>
  </div>