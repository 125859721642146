import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { httpTranslateLoader } from '../core/core.module';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactsModule } from '../contacts/contacts.module';
import { ImgDirective } from './directives/img.directive';
import { CommentComponent } from './components/comment/comment.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ManselPaginationComponent } from './components/mansel-pagination/mansel-pagination.component';
import { SummaryNewsComponent } from './components/summary-news/summary-news.component';
import { StockStatusComponent } from './components/stock-status/stock-status.component';
import { CompanyComponent } from './components/company/company.component';
import { DraftMonthComponent } from './components/draft-month/draft-month.component';
import { SummaryInfoComponent } from './components/summary-info/summary-info.component';
import { SummaryAnalysisComponent } from './components/summary-analysis/summary-analysis.component';
import { DraftDividendComponent } from './components/draft-dividend/draft-dividend.component';
import { DraftCapitalIncreaseComponent } from './components/draft-capital-increase/draft-capital-increase.component';
import { SummaryRecommendationComponent } from './components/summary-recommendation/summary-recommendation.component';
import { DraftCalculateComponent } from './components/draft-calculate/draft-calculate.component';
import { SanitizeHtmlPipe } from './pipe/sanitize-html.pipe';
import { AdsHeaderComponent } from './components/ads-header/ads-header.component';
import { WebPushComponent } from './components/web-push/web-push.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { StockChartComponent } from './components/stock-chart/stock-chart.component';
import { CoinChartComponent } from './components/coin-chart/coin-chart.component';
import { FinancialStatementComponent } from './components/financial-statement/financial-statement.component';
import { ImageUrlPipe } from './pipe/image-url.pipe';
import { PageContentComponent } from './components/page-content/page-content.component';


@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    ImgDirective,
    CommentComponent,
    ManselPaginationComponent,
    SummaryNewsComponent,
    StockStatusComponent,
    CompanyComponent,
    DraftMonthComponent,
    SummaryInfoComponent,
    SummaryAnalysisComponent,
    DraftDividendComponent,
    DraftCalculateComponent,
    DraftCapitalIncreaseComponent,
    SummaryRecommendationComponent,
    SanitizeHtmlPipe,
    AdsHeaderComponent,
    WebPushComponent,
    ConfirmationDialogComponent,
    StockChartComponent,
    CoinChartComponent,
    FinancialStatementComponent,
    ImageUrlPipe,
    PageContentComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    ContactsModule,
    FontAwesomeModule,
    NgbModule,
    TranslateModule.forChild({
      loader: {
      provide: TranslateLoader,
      useFactory: httpTranslateLoader,
      deps: [HttpClient]
    },
    isolate: false
    }),
  ],
  exports: [
    CommonModule,
    HeaderComponent,
    FooterComponent,
    CommentComponent,
    ManselPaginationComponent,
    ImgDirective,
    SummaryNewsComponent,
    FontAwesomeModule,
    StockStatusComponent,
    CompanyComponent,
    StockChartComponent,
    CoinChartComponent,
    DraftMonthComponent,
    SummaryInfoComponent,
    SummaryAnalysisComponent,
    DraftDividendComponent,
    SummaryRecommendationComponent,
    DraftCalculateComponent,
    SanitizeHtmlPipe,
    AdsHeaderComponent,
    WebPushComponent,
    ConfirmationDialogComponent,
    FinancialStatementComponent,
    ImageUrlPipe,
    PageContentComponent
  ]

})
export class SharedModule { }
