<div id="wrapper" class="wrapper clearfix">
  <section id="tabs" class="project-tab tab-edit">
    <div class="container pairblank">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-8 mb-30-xs mb-30-sm">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="container pairblank">
                <div class="row mb-3" style="padding: 15px;">
                  <form [formGroup]="evenDistributionForm" (ngSubmit)="onSubmit()" novalidate>
                    <h1><a [routerLink]="['/calculate/esit-dagitim-hesaplama']" title="Eşit Dağıtım Hesaplama">Eşit Dağıtım Hesaplama</a></h1>
                    <p>Halka Arz Büyüklüğü:</p>
                    <input type="number" formControlName="ipoSize" class="form-control mt-3 mb-3"
                           placeholder="Halka Arz Büyüklüğünü Giriniz" aria-describedby="basic-addon2">
                    <p>Kişi Sayısı:</p>
                    <input type="number" formControlName="numberOfPeople" class="form-control mt-3 mb-3"
                           placeholder="Kişi Sayısı Giriniz" aria-describedby="basic-addon2">
                    <label class='mt-3 mb-3' *ngIf="result" style="display:block">Sonuç: %{{result}}</label>
                    <button class="btn btn-primary mt-3 mb-3" i18n="@@buttonCalculator">Hesapla</button>
                  </form>
                  <div class="widget market-pricing market-list card mb-3" style="position: relative; padding:10px;">
                    <div class="row">
                      <div class="col-4" style="width:auto;height:auto;">
                        <picture>
                          <source media="(max-width: 90px)" srcset="esit-dagitim.webp" />
                          <source media="(min-width: 90px)" srcset="esit-dagitim.webp" />
                          <img loading="lazy" width="90px" height="60px" src="esit-dagitim.webp" alt="Eşit Dağıtım"
                               title="Eşit Dağıtım">
                        </picture>
                      </div>
                      <div class="col-8">
                        <h3 class="h5"><strong>Eşit Dağıtım Nedir?</strong></h3> Halka arzda eşit dağıtım yöntemi borsa şirketleri ile alakalı bir konuyu kapsamaktadır.
                      </div>
                      <div class="w-100"></div>
                      <br>
                      <p>Borsada yeni halka arz edilen şirketlerin çeşitli halka arz yöntemleri vardır. Bunlardan bir tanesi de halka arz eşit dağıtım yöntemidir. Bu sistemde halka arz edilecek hisse senedinin tutarı, başvuran kişi ve hesap sayısına bölünerek bulunur.</p>
                      <p>Eşit dağıtım yöntemi dağıtılacak hisse senetlerinin halka arza başvuran kişi ve hesap sayısına bölünmesiyle elde edilir. Örneğin 100.000 hisse senedi satışı yapılan bir halka arz işleminde hisse senedi fiyatı 10 TL’den satışa çıkmış olsun. 100.000 x 10=1.000.000 TL’lik bir halka arz işlemi yapılacak.</p>
                      <p>Bu halk arz için 1.000 kişi talepte bulunduysa 1.000.000 / 1.000 kişi= 1.000 TL’lik hisse alabilirsiniz.</p>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4">
          <app-draft-calculate></app-draft-calculate>
          <app-draft-dividend [toYear]="toYear"></app-draft-dividend>
          <app-summary-info></app-summary-info>
          <app-summary-analysis [imageStatus]="true" [count]="5"></app-summary-analysis>
        </div>
      </div>
    </div>
  </section>
  <div id="back-to-top" class="backtop">
    <fa-icon [icon]="faArrUp"></fa-icon>
  </div>
