import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-leverage-ratio',
  templateUrl: './leverage-ratio.component.html',
  styleUrls: ['./leverage-ratio.component.scss']
})
export class LeverageRatioComponent implements OnInit {
  domainURL=environment.domain+"/"+(this.localId+"").substring(0,2)+"/";
  leverageRatioForm!: FormGroup;
  submitted = false;
  result:any;
  faArrUp=faArrowUp;
  toYear=(new Date()).getFullYear();
  
  constructor(
    @Inject(LOCALE_ID) private localId: Object,
  ) { }

  ngOnInit(): void {
    this.leverageRatioForm = this.createFormGroup()
  }
  createFormGroup(): FormGroup {
    return new FormGroup({
      totalDebt: new FormControl('',[Validators.required, Validators.minLength(1)]),
      totalAssets: new FormControl('',[Validators.required, Validators.minLength(1)])
    })
  }

  onSubmit() {
    this.submitted = true;
    if (!this.leverageRatioForm.invalid) {
      this.result=this.leverageRatioForm.controls["totalDebt"].value/this.leverageRatioForm.controls["totalAssets"].value;
      this.result=this.result.toFixed(5);
    }else{
      return;
    }
  }
}
