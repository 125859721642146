import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-acid-test-ratio',
  templateUrl: './acid-test-ratio.component.html',
  styleUrls: ['./acid-test-ratio.component.scss']
})
export class AcidTestRatioComponent implements OnInit {
result: any;
submitted: boolean;
acidTestRatioForm: FormGroup;
faArrUp=faArrowUp;
toYear=(new Date()).getFullYear();
  constructor() { }

  ngOnInit(): void {
    this.acidTestRatioForm = this.createFormGroup()
  }
  createFormGroup(): FormGroup {
    return new FormGroup({
      currentAssets: new FormControl('',[Validators.required, Validators.minLength(1)]),
      stocks: new FormControl('',[Validators.required, Validators.minLength(1)]),
      shortTermLiabilities: new FormControl('',[Validators.required, Validators.minLength(1)])
    })
  }
  
onSubmit() {
  this.submitted = true;
  if (!this.acidTestRatioForm.invalid) {
    this.result=(this.acidTestRatioForm.controls["currentAssets"].value-this.acidTestRatioForm.controls["stocks"].value)/this.acidTestRatioForm.controls["shortTermLiabilities"].value
  this.result=this.result.toFixed(5);
  }else{
    return;
  }
}

}
